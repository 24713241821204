const actions = {
	SET_STATE: "docType/SET_STATE",
	GET_LIST: "docType/GET_LIST",
	GET_DOC: "docType/GET_DOC",
	CREATE: "docType/CREATE",
	UPDATE: "docType/UPDATE",
	DELETE: "docType/DELETE",
	SET_CURRENT_PAGE: "docType/SET_CURRENT_PAGE",
	SET_LIMIT: "docType/SET_LIMIT",
	SET_SORTING: "docType/SET_SORTING",
	SET_SEARCH_TEXT: "docType/SET_SEARCH_TEXT",
	SET_FILTERS: "docType/SET_FILTERS",
	RESET_FILTERS: "docType/RESET_FILTERS",
};

export default actions;
