const actions = {
	SET_STATE: "staff/SET_STATE",
	GET_LIST: "staff/GET_LIST",
	GET_DOC: "staff/GET_DOC",
	RESET_PASSWORD: "staff/RESET_PASSWORD",
	FORCE_VERIFY: "staff/FORCE_VERIFY",
	ACTIVATE: "staff/ACTIVATE",
	DEACTIVATE: "staff/DEACTIVATE",
	SET_CURRENT_PAGE: "staff/SET_CURRENT_PAGE",
	SET_LIMIT: "staff/SET_LIMIT",
	SET_SORTING: "staff/SET_SORTING",
	SET_SEARCH_TEXT: "staff/SET_SEARCH_TEXT",
	SET_FILTERS: "staff/SET_FILTERS",
	RESET_FILTERS: "staff/RESET_FILTERS",
};

export default actions;
