export default async function getMenuData() {
	return [
		{
			title: "Dashboard",
			key: "dashboard",
			icon: "fe fe-grid",
			url: "/",
			// roles: ['admin'],
		},
		// { type: "divider" },
		{
			title: "Account Number",
			key: "account-number",
			icon: "fe fe-credit-card",
			url: "/account-number",
			// roles: ['admin'],
		},
		{
			title: "Staff",
			key: "staff",
			icon: "fe fe-user-check",
			url: "/staff",
			// roles: ['admin'],
		},

		{
			title: "Driver",
			key: "driver",
			icon: "fe fe-user",
			url: "/driver",
			// roles: ['admin'],
		},
		{
			title: "Driver Licence",
			key: "driver-licence",
			icon: "fe fe-paperclip",
			url: "/driver-licence",
			// roles: ['admin'],
		},

		{
			title: "Vendor",
			key: "vendor",
			icon: "fe fe-shopping-bag",
			url: "/vendor",
			// roles: ['admin'],
		},
		{
			title: "Location",
			key: "location",
			icon: "fe fe-map-pin",
			url: "/location",
			// roles: ['admin'],
		},
		{
			title: "Company",
			key: "company",
			icon: "fe fe-layers",
			url: "/company",
			// roles: ['admin'],
		},
		{
			title: "Financier",
			key: "financier",
			icon: "fe fe-briefcase",
			url: "/financier",
			// roles: ['admin'],
		},
		{
			// type: "group",
			title: "Vehicle Management",
			key: "vehicleManagement",
			icon: "fe fe-truck",
			children: [
				{
					title: "Vehicle",
					key: "vehicle",
					icon: "fe fe-truck",
					url: "/vehicle",
					// roles: ['admin'],
				},
				{
					title: "Group",
					key: "vehicleGroup",
					icon: "fe fe-hash",
					url: "/vehicle-group",
					// roles: ['admin'],
				},
				{
					title: "Make",
					key: "vehicleMake",
					icon: "fe fe-target",
					url: "/vehicle-make",
					// roles: ['admin'],
				},
				{
					title: "Service",
					key: "vehicleService",
					icon: "fe fe-tag",
					url: "/vehicle-service",
					// roles: ['admin'],
				},
				{
					title: "Type",
					key: "vehicleType",
					icon: "fe fe-bookmark",
					url: "/vehicle-type",
					// roles: ['admin'],
				},
				{
					title: "Transmission Type",
					key: "transmissionType",
					icon: "fe fe-tag",
					url: "/transmission-type",
					// roles: ['admin'],
				},
				{
					title: "Battery Spec",
					key: "batterySpec",
					icon: "fe fe-tag",
					url: "/battery-spec",
					// roles: ['admin'],
				},
				{
					title: "Tyre Spec",
					key: "tyreSpec",
					icon: "fe fe-tag",
					url: "/tyre-spec",
					// roles: ['admin'],
				},
				{
					title: "Supplier",
					key: "vehicleSupplier",
					icon: "fe fe-navigation",
					url: "/vehicle-supplier",
					// roles: ['admin'],
				},
			],
		},
		// { type: "divider" },

		{
			// type: "group",
			title: "Office Management",
			key: "officeManagement",
			icon: "lnr lnr-apartment",
			children: [
				{
					title: "Office",
					key: "office",
					icon: "lnr lnr-apartment",
					url: "/office",
					// roles: ['admin'],
				},
				{
					title: "Unit",
					key: "unit",
					icon: "fe fe-users",
					url: "/unit",
					// roles: ['admin'],
				},
			],
		},
		{
			// type: "group",
			title: "Documentation",
			key: "documentation",
			icon: "fe fe-book-open",
			children: [
				{
					title: "Insurance Certificate",
					key: "docInsuranceCert",
					icon: "fe fe-file-text",
					url: "/doc-insurance-cert",
					// roles: ['admin'],
				},
				{
					title: "Folder",
					key: "docFolder",
					icon: "fe fe-folder",
					url: "/doc-folder",
					// roles: ['admin'],
				},
				{
					title: "Type",
					key: "docType",
					icon: "fe fe-tag",
					url: "/doc-type",
					// roles: ['admin'],
				},
				{
					title: "Asset File",
					key: "docAssetFile",
					icon: "fe fe-file",
					url: "/doc-asset",
					// roles: ['admin'],
				},
				{
					title: "Asset Type",
					key: "docAssetType",
					icon: "fe fe-tag",
					url: "/doc-asset-type",
					// roles: ['admin'],
				},
				{
					title: "Entity Type",
					key: "entityType",
					icon: "fe fe-tag",
					url: "/entity-type",
					// roles: ['admin'],
				},
			],
		},

		{
			// type: "group",
			title: "Fueling Management",
			key: "fuelingManagement",
			icon: "fe fe-zap",
			children: [
				{
					title: "Fueling",
					key: "fueling",
					icon: "fe fe-zap",
					url: "/fueling",
					// roles: ['admin'],
				},
				{
					title: "Fueling Type",
					key: "fuelingType",
					icon: "fe fe-tag",
					url: "/fueling-type",
					// roles: ['admin'],
				},
				{
					title: "Fuel Type",
					key: "fuelType",
					icon: "fe fe-bookmark",
					url: "/fuel-type",
					// roles: ['admin'],
				},

				{
					title: "Filling Station",
					key: "fillingStation",
					icon: "fe fe-power",
					url: "/filling-station",
					// roles: ['admin'],
				},
			],
		},
		{
			// type: "group",
			title: "Booking Management",
			key: "bookingManagement",
			icon: "fe fe-calendar",
			children: [
				{
					title: "Reservation",
					key: "reservation",
					icon: "fe fe-calendar",
					url: "/reservation",
					// roles: ['admin'],
				},
				{
					title: "Trip",
					key: "trip",
					icon: "fe fe-wind",
					url: "/trip",
					// roles: ['admin'],
				},
				{
					title: "Trip Status",
					key: "tripStatus",
					icon: "fe fe-bookmark",
					url: "/trip-status",
					// roles: ['admin'],
				},
				{
					title: "Invoice Status",
					key: "invoiceStatus",
					icon: "fe fe-bookmark",
					url: "/invoice-status",
					// roles: ['admin'],
				},
				{
					title: "Additional Service",
					key: "additionalService",
					icon: "fe fe-tag",
					url: "/additional-service",
					// roles: ['admin'],
				},
				{
					title: "Cost",
					key: "cost",
					icon: "fe fe-dollar-sign",
					url: "/cost",
					// roles: ['admin'],
				},
				{
					title: "User Approval Request",
					key: "userApprovalRequest",
					icon: "fe fe-git-pull-request",
					url: "/user-approval-request",
					// roles: ['admin'],
				},
				{
					title: "Booking Invoice",
					key: "bookingInvoice",
					icon: "fe fe-printer",
					url: "/booking-invoice",
					// roles: ['admin'],
				},
			],
		},

		{
			// type: "group",
			title: "Contract Management",
			key: "contractManagement",
			icon: "fe fe-file-text",
			children: [
				{
					title: "Contract",
					key: "contract",
					icon: "fe fe-file-text",
					url: "/contract",
					// roles: ['admin'],
				},
				{
					title: "Contract Type",
					key: "contractType",
					icon: "fe fe-tag",
					url: "/contract-type",
					// roles: ['admin'],
				},
				{
					title: "Service Type",
					key: "serviceType",
					icon: "fe fe-bookmark",
					url: "/service-type",
					// roles: ['admin'],
				},
				{
					title: "Asset Supplier",
					key: "assetSupplier",
					icon: "fe fe-user-check",
					url: "/asset-supplier",
					// roles: ['admin'],
				},
			],
		},

		{
			// type: "group",
			title: "Maintenance",
			key: "maintenance",
			icon: "fe fe-settings",
			children: [
				{
					title: "Workshop",
					key: "maintenanceWorkshop",
					icon: "fe fe-droplet",
					url: "/maintenance-workshop",
					// roles: ['admin'],
				},
				{
					title: "Rating",
					key: "maintenanceRating",
					icon: "fe fe-star",
					url: "/maintenance-rating",
					// roles: ['admin'],
				},
				{
					title: "Type",
					key: "maintenanceType",
					icon: "fe fe-tag",
					url: "/maintenance-type",
					// roles: ['admin'],
				},
				{
					title: "Sub Type",
					key: "maintenanceSubType",
					icon: "fe fe-tag",
					url: "/maintenance-sub-type",
					// roles: ['admin'],
				},
				{
					title: "Fault Type",
					key: "maintenanceFaultType",
					icon: "fe fe-bookmark",
					url: "/maintenance-fault-type",
					// roles: ['admin'],
				},
			],
		},
	];
}
