import apiClient from "../axios";

// Vehicle Supplier - List
export const getList =
	async (/* queries: { start: number; length: number } */) =>
		apiClient
			.get(`/vehicle-suppliers`, {
				/* headers: { "cache-control": "no-cache" } */
			})
			.then((res) => (res && res.data ? res.data : false))
			.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/vehicle-suppliers/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create a vehicle supplier
export const create = async (data: any) =>
	apiClient
		.post("/vehicle-suppliers", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update a vehicle supplier
export const update = async (data: any) =>
	apiClient
		.put(`/vehicle-suppliers/${data.id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Delete a vehicle supplier
export const deleteVehicleSupplier = async (data: {
	reason: string;
	id: string | number;
}) =>
	apiClient
		.delete(`/vehicle-suppliers/${data.id}`, { data })
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Get a supplier list of vehicles
export const getVehicles = async (id: string) =>
	apiClient
		.get(`/vehicle-suppliers/${id}/vehicles`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
