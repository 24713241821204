const actions = {
	SET_STATE: "trip/SET_STATE",
	GET_LIST: "trip/GET_LIST",
	GET_DOC: "trip/GET_DOC",
	CREATE: "trip/CREATE",
	UPDATE: "trip/UPDATE",
	DELETE: "trip/DELETE",
	SET_CURRENT_PAGE: "trip/SET_CURRENT_PAGE",
	SET_LIMIT: "trip/SET_LIMIT",
	SET_SORTING: "trip/SET_SORTING",
	SET_SEARCH_TEXT: "trip/SET_SEARCH_TEXT",
	SET_FILTERS: "trip/SET_FILTERS",
	RESET_FILTERS: "trip/RESET_FILTERS",
};

export default actions;
