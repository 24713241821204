const actions = {
	SET_STATE: "unit/SET_STATE",
	GET_LIST: "unit/GET_LIST",
	GET_PUBLIC_LIST: "unit/GET_PUBLIC_LIST",
	GET_DOC: "unit/GET_DOC",
	CREATE: "unit/CREATE",
	UPDATE: "unit/UPDATE",
	ACTIVATE: "unit/ACTIVATE",
	DEACTIVATE: "unit/DEACTIVATE",
	CHANGE_HOD: "unit/CHANGE_HOD",
	SET_CURRENT_PAGE: "unit/SET_CURRENT_PAGE",
	SET_LIMIT: "unit/SET_LIMIT",
	SET_SORTING: "unit/SET_SORTING",
	SET_SEARCH_TEXT: "unit/SET_SEARCH_TEXT",
	SET_FILTERS: "unit/SET_FILTERS",
	RESET_FILTERS: "unit/RESET_FILTERS",
};

export default actions;
