import React from "react";
import { Button, Col, Row, Space, Typography } from "antd";
import { motion } from "framer-motion";

import { history } from "@redux/store";

interface IErrorBoundaryProps {
	children: JSX.Element;
}

const { Text, Title } = Typography;

export default class ErrorBoundary extends React.Component<
	IErrorBoundaryProps,
	any
> {
	constructor(props: IErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: any, errorInfo: any) {
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, errorInfo)
	}

	render() {
		const { hasError } = this.state;
		const { children } = this.props;

		if (hasError) {
			// You can render any custom fallback UI
			// history.replace('/auth/500')

			// return <h1>Something went wrong.</h1>;
			return (
				<motion.div
					initial={{ opacity: 0, y: "30px" }}
					animate={{ opacity: 1, y: "0px" }}
					transition={{ delay: 0.2, ease: "easeOut" }}
				>
					<Row
						style={{
							minHeight: "calc(100vh - 61px)",
							justifyContent: "center",
						}}
					>
						<Col
							xs={24}
							md={12}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Space direction="vertical" align="center" size="large">
								<img
									src={`${process.env.PUBLIC_URL}/resources/images/error-500.svg`}
									alt="500 error"
									width={350}
								/>

								<Title
									style={{
										color: "#255774",
										fontWeight: 700,
										fontSize: "0.9rem",
									}}
									className="m-0"
									level={5}
								>
									ERROR 500
								</Title>

								<Title style={{ fontWeight: 700 }} className="m-0" level={2}>
									Oops, that is our bad.
								</Title>

								<Text strong>We are not exactly sure what happened.</Text>

								<Button
									size="large"
									type="primary"
									onClick={() => history.push("/")}
								>
									Go to dashboard
								</Button>
							</Space>
						</Col>
					</Row>
				</motion.div>
			);
		}

		return children;
	}
}
