const actions = {
	SET_STATE: "staffPermission/SET_STATE",
	GET_LIST: "staffPermission/GET_LIST",
	ADD: "staffPermission/ADD",
	DELETE: "staffPermission/DELETE",
	SET_CURRENT_PAGE: "staffPermission/SET_CURRENT_PAGE",
	SET_LIMIT: "staffPermission/SET_LIMIT",
	SET_SORTING: "staffPermission/SET_SORTING",
	SET_SEARCH_TEXT: "staffPermission/SET_SEARCH_TEXT",
	SET_FILTERS: "staffPermission/SET_FILTERS",
	RESET_FILTERS: "staffPermission/RESET_FILTERS",
	SET_ACTIVE_STAFF: "staffPermission/SET_ACTIVE_STAFF",
};

export default actions;
