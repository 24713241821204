const actions = {
	SET_STATE: "transmissionType/SET_STATE",
	GET_LIST: "transmissionType/GET_LIST",
	GET_DOC: "transmissionType/GET_DOC",
	CREATE: "transmissionType/CREATE",
	UPDATE: "transmissionType/UPDATE",
	DELETE: "transmissionType/DELETE",
	SET_CURRENT_PAGE: "transmissionType/SET_CURRENT_PAGE",
	SET_LIMIT: "transmissionType/SET_LIMIT",
	SET_SORTING: "transmissionType/SET_SORTING",
	SET_SEARCH_TEXT: "transmissionType/SET_SEARCH_TEXT",
	SET_FILTERS: "transmissionType/SET_FILTERS",
	RESET_FILTERS: "transmissionType/RESET_FILTERS",
};

export default actions;
