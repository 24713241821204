import React, { useState, useEffect } from "react";
// import ReactDOM from "react-dom/client";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
	Drawer,
	Layout,
	Menu,
	theme,
	// Typography,
} from "antd";
import store from "store";
import { find } from "lodash";
import MediaQuery, { useMediaQuery } from "react-responsive";
import Scrollbar from "react-smooth-scrollbar";
import SmoothScrollbar from "smooth-scrollbar";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";

// import { ReactComponent as Logo } from "@icons/logo.svg";
// import { ReactComponent as LogoM } from "@icons/logo-m.svg";
import findChildNodeRecursively from "@utils/find";

SmoothScrollbar.use(OverscrollPlugin);

// const { Title, Text } = Typography;
const { Sider } = Layout;

// type MenuItem = Required<MenuProps>["items"][number];

type menuDataType = {
	type?: string;
	title: string;
	key: string;
	icon?: string;
	url: string;
	children?: Omit<menuDataType, "children">[];
};

const generateMenuItems = (
	menuData: menuDataType[],
	options: { navigate: any; dispatch: any; canShowDrawer: boolean }
) => {
	const generateItem = (item: menuDataType) => {
		const { key, title, url, icon, type } = item;

		return {
			key,
			onClick: () => {
				if (options.canShowDrawer) {
					options.dispatch({
						type: "menu/SET_STATE",
						payload: { showDrawer: false },
					});
					if (url) setTimeout(() => options.navigate(`${url}`), 50); //eslint-disable-line
				} else {
					if (url) options.navigate(`${url}`); //eslint-disable-line
				}
			},
			label: title,
			type,
			icon: <i className={`${icon}`} style={{ marginRight: 10 }} />,
		};
	};

	const generateSubmenu = (submenuItems: menuDataType[]) =>
		submenuItems.map((item) => generateItem(item));

	return menuData.map((item) => {
		if (item.children && item.children.length > 0) {
			return {
				...generateItem(item),
				children: generateSubmenu(item.children),
			};
		}

		return generateItem(item);
	});
};

const MenuLeft = () => {
	const { token } = theme.useToken();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const canShowDrawer = useMediaQuery({ maxWidth: 768 });
	const { menuData, showDrawer, changeKey } = useSelector(
		(state: any) => state.menu
	);

	const [collapsed, setCollapsed] = useState(false);
	const [selectedKeys, setSelectedKeys] = useState(
		store.get("app.menu.selectedKeys") || []
	);
	const [openedKeys, setOpenedKeys] = useState(
		store.get("app.menu.openedKeys") || []
	);
	const scrollbarRef = React.useRef<any>(null);
	const menuRef = React.useRef<any>(null);

	useEffect(() => {
		applySelectedKeys();
	}, [pathname, menuData]);

	useEffect(() => {
		const elem = menuRef.current?.menu?.list?.childNodes;
		let activeMenuItem = null;

		const timeout = setTimeout(() => {
			activeMenuItem = findChildNodeRecursively(
				elem,
				"ant-menu-submenu-selected",
				"ant-menu-item-selected"
			);

			if (activeMenuItem) {
				scrollbarRef.current?.scrollbar.scrollIntoView(activeMenuItem);
			}
		}, 500);

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	useEffect(() => {
		const elem = menuRef.current?.menu?.list?.childNodes;
		let activeMenuItem = null;

		const timeout = setTimeout(() => {
			activeMenuItem = findChildNodeRecursively(
				elem,
				"ant-menu-submenu-selected",
				"ant-menu-item-selected"
			);

			if (activeMenuItem) {
				scrollbarRef.current?.scrollbar.scrollIntoView(activeMenuItem);
			}
		}, 500);

		return () => {
			clearTimeout(timeout);
		};
	}, [showDrawer]);

	const applySelectedKeys = () => {
		const flattenItems = (items: any, key: any) =>
			items.reduce((flattenedItems: any, item: any) => {
				flattenedItems.push(item);
				if (Array.isArray(item[key])) {
					return flattenedItems.concat(flattenItems(item[key], key));
				}
				return flattenedItems;
			}, []);

		const selectedItem = find(flattenItems(menuData, "children"), [
			"url",
			`/${pathname.split("/")[1]}`,
		]);

		setSelectedKeys(selectedItem ? [selectedItem.key] : []);
	};

	const onOpenChange = (keys: any) => {
		store.set("app.menu.openedKeys", keys);
		setOpenedKeys(keys);
	};

	return (
		<>
			<MediaQuery minWidth={768}>
				<Sider
					key={changeKey}
					style={{
						backgroundColor: "#fff",
					}}
					width={250}
					collapsible
					collapsed={collapsed}
					onCollapse={(value) => setCollapsed(value)}
				>
					<div
						className="px-2"
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							height: 80,
						}}
					>
						{!collapsed && (
							<Link to="/">
								<img
									src={`${process.env.PUBLIC_URL}/resources/images/logo500.png`}
									alt="Logo"
									height={70}
								/>
							</Link>
						)}
						{collapsed && (
							<Link to="/">
								<img
									src={`${process.env.PUBLIC_URL}/resources/images/logo500.png`}
									alt="Logo"
									height={60}
								/>
							</Link>
						)}
					</div>

					<Scrollbar
						ref={scrollbarRef}
						damping={0.1}
						thumbMinSize={10}
						renderByPixels
						continuousScrolling
						style={{
							height: `calc(100vh - 128px)`,
							width: "100%",
							overflowY: "auto",
							overflowX: "hidden",
							scrollbarColor: "light",
							/* borderRightWidth: 1,
							borderRightStyle: "solid",
							borderRightColor: token.colorBorderBg, */
						}}
					>
						<Menu
							// onClick={handleClick}
							ref={menuRef}
							selectedKeys={selectedKeys}
							openKeys={openedKeys}
							onOpenChange={onOpenChange}
							mode="inline"
							items={generateMenuItems(menuData, {
								navigate,
								dispatch,
								canShowDrawer,
							})}
							style={{ border: "none" }}
						/>
					</Scrollbar>
				</Sider>
			</MediaQuery>

			<MediaQuery maxWidth={768}>
				<Drawer
					key={changeKey}
					style={{
						backgroundColor: "#fff",
					}}
					width={250}
					placement="left"
					onClose={() =>
						dispatch({
							type: "menu/SET_STATE",
							payload: { showDrawer: false },
						})
					}
					open={showDrawer}
					closable={false}
					bodyStyle={{ padding: 0 }}
				>
					<div
						className="px-2"
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							height: 60,
						}}
					>
						<img
							src={`${process.env.PUBLIC_URL}/resources/images/logo500.png`}
							alt="Logo"
							height={60}
						/>
					</div>

					<Scrollbar
						ref={scrollbarRef}
						damping={0.1}
						thumbMinSize={10}
						renderByPixels
						continuousScrolling
						style={{
							height: `calc(100vh - 60px)`,
							width: "100%",
							overflowY: "auto",
							scrollbarColor: "light",
						}}
					>
						<Menu
							ref={menuRef}
							// onClick={handleClick}
							selectedKeys={selectedKeys}
							openKeys={openedKeys}
							onOpenChange={onOpenChange}
							mode="inline"
							items={generateMenuItems(menuData, {
								navigate,
								dispatch,
								canShowDrawer,
							})}
							style={{ border: "none" }}
							className="py-3"
						/>
					</Scrollbar>
				</Drawer>
			</MediaQuery>
		</>
	);
};

export default MenuLeft;
