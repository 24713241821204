import apiClient from "../axios";

// Financier - List
export const getList =
	async (/* queries: { start: number; length: number } */) =>
		apiClient
			.get(`/financiers`, {
				/* headers: { "cache-control": "no-cache" } */
			})
			.then((res) => (res && res.data ? res.data : false))
			.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/financiers/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create a financier
export const create = async (data: any) =>
	apiClient
		.post("/financiers", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update a financier
export const update = async (data: any) =>
	apiClient
		.put(`/financiers/${data.id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Delete a financier
export const deleteFinancier = async (data: {
	reason: string;
	id: string | number;
}) =>
	apiClient
		.delete(`/financiers/${data.id}`, { data })
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Activate a financier
export const activate = async (data: { reason: string; id: string | number }) =>
	apiClient
		.patch(`/financiers/${data.id}/activate`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Deactivate a financier
export const deactivate = async (data: {
	reason: string;
	id: string | number;
}) =>
	apiClient
		.patch(`/financiers/${data.id}/deactivate`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
