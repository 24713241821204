import React /* useState  , { useReducer, FC } */ from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
	Layout,
	// Col,
	// Drawer,
	Button,
	Space,
	Dropdown,
	Avatar,
	Typography,
	theme,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import MediaQuery from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import Scrollbar from "react-smooth-scrollbar";
import SmoothScrollbar from "smooth-scrollbar";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";
// import { motion } from "framer-motion";

import MenuLeft from "@components/main/Menu";
import { ReactComponent as NotificationIcon } from "@icons/notification.svg";
import { ReactComponent as MenuIcon } from "@icons/menu-outline.svg";

SmoothScrollbar.use(OverscrollPlugin);

// import style from "./style.module.scss";
// import './style.css';

const { Text } = Typography;
const { Header, Content } = Layout;

type MainLayoutProps = {
	children: React.ReactNode;
};

function MainLayout({ children }: MainLayoutProps) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { token } = theme.useToken();
	const { user } = useSelector((state: any) => state.account);

	const menu = [
		{
			key: "1",
			onClick: () => navigate("/profile"),
			label: (
				<>
					<i className="fe fe-user" style={{ marginRight: 15 }} />
					<span>Profile</span>
				</>
			),
		},
		{
			key: "2",
			onClick: () => logout(),
			label: (
				<>
					<i className="fe fe-log-out" style={{ marginRight: 15 }} />
					<span>Log out</span>
				</>
			),
		},
	];

	const logout = () =>
		dispatch({
			type: "account/LOGOUT",
			payload: {
				navigate,
			},
		});

	return (
		<>
			<Helmet titleTemplate="%s | Groot" />
			<Layout style={{ height: "100vh" }}>
				<MenuLeft />
				<Layout>
					{/* <motion.div
							initial={{ opacity: 0.7, translateY: -60 }}
							animate={{ opacity: 1, translateY: 0 }}
							transition={{ duration: 0.2. ease: "easeOut" }}
						> */}
					<Header
						className="px-2 px-md-4 px-lg-5"
						style={{
							display: "flex",
							backgroundColor: "#fff",
							alignItems: "center",
							height: "60px",
							justifyContent: "space-between",
							// borderBottomWidth: 1,
							// borderBottomStyle: "solid",
							// borderBottomColor: token.colorBorderBg,
						}}
					>
						<div className="d-flex justify-content-center align-items-center">
							<MediaQuery maxWidth={768}>
								<Button
									icon={<MenuIcon height={22} />}
									type="default"
									className="mr-2 border-0"
									style={{ boxShadow: token.boxShadowSecondary }}
									onClick={() =>
										dispatch({
											type: "menu/SET_STATE",
											payload: { showDrawer: true },
										})
									}
								/>
							</MediaQuery>
						</div>

						<Space
							align="center"
							size="middle"
							style={{ display: "flex", alignItems: "center", height: 60 }}
						>
							<Button
								size="large"
								// type="ghost"
								shape="circle"
								icon={<NotificationIcon />}
							/>

							<Dropdown
								className="pl-4"
								menu={{ items: menu, style: { width: 170 } }}
								arrow
								placement="bottomRight"
							>
								<Avatar
									size="large"
									icon={<UserOutlined />}
									src={user?.avatar?.preview}
									className="mr-3"
								/>
							</Dropdown>
						</Space>
					</Header>
					{/* </motion.div> */}

					<MediaQuery maxWidth={768}>
						<Content
							style={{
								backgroundColor: token.colorPrimaryBg,
								minHeight: "calc(100vh - 61px)",
							}}
							className="px-2 px-md-4 px-lg-5"
						>
							<div>{children}</div>
						</Content>
					</MediaQuery>

					<MediaQuery minWidth={768}>
						<Scrollbar
							damping={0.1}
							thumbMinSize={10}
							renderByPixels
							continuousScrolling
							style={{
								height: `calc(100vh - 61px)`,
								width: "100%",
								overflowY: "auto",
								scrollbarColor: "light",
								backgroundColor: token.colorPrimaryBg,
							}}
							className="content-scrollbar"
						>
							<Content
								style={{
									backgroundColor: token.colorPrimaryBg,
									minHeight: "calc(100vh - 61px)",
								}}
								className="px-2 px-md-4 px-lg-5"
							>
								<div>{children}</div>
							</Content>
						</Scrollbar>
					</MediaQuery>
				</Layout>
			</Layout>
		</>
	);
}

export default MainLayout;
