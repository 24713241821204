import apiClient from "../axios";

// Maintenance Sub Type - List
export const getList =
	async (/* queries: { start: number; length: number } */) =>
		apiClient
			.get(`/maintenance/sub-types`, {
				/* headers: { "cache-control": "no-cache" } */
			})
			.then((res) => (res && res.data ? res.data : false))
			.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/maintenance/sub-types/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create an maintenance/sub-types
export const create = async (data: any) =>
	apiClient
		.post("/maintenance/sub-types", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update an maintenance/sub-types
export const update = async (data: any) =>
	apiClient
		.put(`/maintenance/sub-types/${data.id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Activate an maintenance/sub-types
export const activate = async (data: any) =>
	apiClient
		.patch(`/maintenance/sub-types/${data.id}/activate`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Deactivate an maintenance/sub-types
export const deactivate = async (data: any) =>
	apiClient
		.patch(`/maintenance/sub-types/${data.id}/deactivate`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
