import apiClient from "../axios";

// Transmission Type - List
export const getList =
	async (/* queries: { start: number; length: number } */) =>
		apiClient
			.get(`/transmission-types`, {
				/* headers: { "cache-control": "no-cache" } */
			})
			.then((res) => (res && res.data ? res.data : false))
			.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/transmission-types/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create a transmission type
export const create = async (data: any) =>
	apiClient
		.post("/transmission-types", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update a transmission type
export const update = async (data: any) =>
	apiClient
		.put(`/transmission-types/${data.id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Delete a transmission type
export const deleteTransmissionType = async (data: {
	reason: string;
	id: string | number;
}) =>
	apiClient
		.delete(`/transmission-types/${data.id}`, { data })
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
