const actions = {
	SET_STATE: "driver/SET_STATE",
	GET_LIST: "driver/GET_LIST",
	GET_DOC: "driver/GET_DOC",
	CREATE: "driver/CREATE",
	UPDATE: "driver/UPDATE",
	DELETE: "driver/DELETE",
	SET_CURRENT_PAGE: "driver/SET_CURRENT_PAGE",
	SET_LIMIT: "driver/SET_LIMIT",
	SET_SORTING: "driver/SET_SORTING",
	SET_SEARCH_TEXT: "driver/SET_SEARCH_TEXT",
	SET_FILTERS: "driver/SET_FILTERS",
	RESET_FILTERS: "driver/RESET_FILTERS",
};

export default actions;
