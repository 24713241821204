const actions = {
	SET_STATE: "maintenanceWorkshop/SET_STATE",
	GET_LIST: "maintenanceWorkshop/GET_LIST",
	GET_DOC: "maintenanceWorkshop/GET_DOC",
	CREATE: "maintenanceWorkshop/CREATE",
	UPDATE: "maintenanceWorkshop/UPDATE",
	DELETE: "maintenanceWorkshop/DELETE",
	ACTIVATE: "maintenanceWorkshop/ACTIVATE",
	DEACTIVATE: "maintenanceWorkshop/DEACTIVATE",
	SET_CURRENT_PAGE: "maintenanceWorkshop/SET_CURRENT_PAGE",
	SET_LIMIT: "maintenanceWorkshop/SET_LIMIT",
	SET_SORTING: "maintenanceWorkshop/SET_SORTING",
	SET_SEARCH_TEXT: "maintenanceWorkshop/SET_SEARCH_TEXT",
	SET_FILTERS: "maintenanceWorkshop/SET_FILTERS",
	RESET_FILTERS: "maintenanceWorkshop/RESET_FILTERS",
};

export default actions;
