const actions = {
	SET_STATE: "batterySpec/SET_STATE",
	GET_LIST: "batterySpec/GET_LIST",
	GET_DOC: "batterySpec/GET_DOC",
	CREATE: "batterySpec/CREATE",
	UPDATE: "batterySpec/UPDATE",
	DELETE: "batterySpec/DELETE",
	SET_CURRENT_PAGE: "batterySpec/SET_CURRENT_PAGE",
	SET_LIMIT: "batterySpec/SET_LIMIT",
	SET_SORTING: "batterySpec/SET_SORTING",
	SET_SEARCH_TEXT: "batterySpec/SET_SEARCH_TEXT",
	SET_FILTERS: "batterySpec/SET_FILTERS",
	RESET_FILTERS: "batterySpec/RESET_FILTERS",
};

export default actions;
