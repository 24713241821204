const actions = {
	SET_STATE: "financier/SET_STATE",
	GET_LIST: "financier/GET_LIST",
	GET_DOC: "financier/GET_DOC",
	CREATE: "financier/CREATE",
	UPDATE: "financier/UPDATE",
	DELETE: "financier/DELETE",
	ACTIVATE: "financier/ACTIVATE",
	DEACTIVATE: "financier/DEACTIVATE",
	SET_CURRENT_PAGE: "financier/SET_CURRENT_PAGE",
	SET_LIMIT: "financier/SET_LIMIT",
	SET_SORTING: "financier/SET_SORTING",
	SET_SEARCH_TEXT: "financier/SET_SEARCH_TEXT",
	SET_FILTERS: "financier/SET_FILTERS",
	RESET_FILTERS: "financier/RESET_FILTERS",
};

export default actions;
