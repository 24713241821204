import React, { lazy, Suspense } from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import ErrorBoundary from "@components/error/ErrorBoundary";
import Layout from "./layouts";
import ScrollToTopOnPageChange from "./components/public/ScrollToTopOnPageChange";

const routes = [
	// Account Auth Routes
	// {
	// 	path: "/account/email-verification-success",
	// 	key: "/email-verification-success",
	// 	Component: lazy(() => import("./pages/auth/EmailVerificationSuccess")),
	// },
	{
		path: "/account/activate",
		key: "/activate",
		Component: lazy(() => import("./pages/auth/Activate")),
	},
	{
		path: "/account/activate-facility",
		key: "/activate-facility",
		Component: lazy(() => import("./pages/auth/ActivateFacility")),
	},
	{
		path: "/account/reset-password",
		key: "/reset-password",
		Component: lazy(() => import("./pages/auth/ResetPassword")),
	},
	{
		path: "/account/reset-password-facility",
		key: "/reset-password-facility",
		Component: lazy(() => import("./pages/auth/ResetPasswordFacility")),
	},

	// Main Routes
	{
		path: "/",
		key: "/landing",
		Component: lazy(() => import("./pages/public/Landing")),
	},
	{
		path: "/privacy-policy",
		key: "/privacy-policy",
		Component: lazy(() => import("./pages/public/Terms")),
	},
	{
		path: "/company",
		key: "/company",
		Component: lazy(() => import("./pages/public/Company")),
	},
	{
		path: "/pricing",
		key: "/pricing",
		Component: lazy(() => import("./pages/public/Pricing")),
	},
	{
		path: "/contact",
		key: "/contact",
		Component: lazy(() => import("./pages/public/Contact")),
	},

	// Error Routes
	{
		path: "/error/404",
		key: "/error404",
		Component: lazy(() => import("./pages/error/Error404")),
	},
	{
		path: "/error/403",
		key: "/error403",
		Component: lazy(() => import("./pages/error/Error403")),
	},
	{
		path: "/error/500",
		key: "/error500",
		Component: lazy(() => import("./pages/error/Error500")),
	},
	{
		path: "/error/bad-token",
		key: "/bad-token",
		Component: lazy(() => import("./pages/error/BadToken")),
	},
	{
		path: "/error/no-account",
		key: "/no-account",
		Component: lazy(() => import("./pages/error/NoAccount")),
	},
];

const AnimatedRoutes = () => {
	const location = useLocation();

	return (
		<AnimatePresence>
			<ScrollToTopOnPageChange />
			<Routes location={location} key={location.pathname}>
				<Route element={<Layout />}>
					{routes.map((route, i) => (
						<Route
							key={route.key}
							path={route.path}
							element={
								<Suspense fallback={null}>
									<ErrorBoundary>
										<route.Component />
									</ErrorBoundary>
								</Suspense>
							}
						/>
					))}
					<Route path="*" element={<Navigate to="/error/404" replace />} />
				</Route>
			</Routes>
		</AnimatePresence>
	);
};

export default AnimatedRoutes;
