import { colord } from "colord";

const primaryColor = "#086375";
const primaryColorLight = "#b7e1e9";

const theme = {
	token: {
		colorPrimary: primaryColor,
		colorPrimaryBg: primaryColorLight,
		colorBgLayout: "#ffffff",
		colorBorderBg: "#E8E8E8",
		colorText: "#3c555a",
		colorTextDescription: "#6e8c92" /* "#856063" */,
		colorLink: primaryColor,
		colorLinkActive: colord(primaryColor).lighten(0.4).toHex(),
		colorLinkHover: colord(primaryColor).lighten(0.2).toHex(),
		fontSizeSM: 14,
		fontFamily:
			"-apple-system, BlinkMacSystemFont, Roboto, Inter, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
		boxShadow: "0px 2px 10px rgba(124, 141, 181, 0.12)",
	},
	components: {
		Input: {
			lineHeight: 2.5,
		},
	},
};

export default theme;
