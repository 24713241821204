const actions = {
	SET_STATE: "entityType/SET_STATE",
	GET_LIST: "entityType/GET_LIST",
	GET_DOC: "entityType/GET_DOC",
	CREATE: "entityType/CREATE",
	UPDATE: "entityType/UPDATE",
	DELETE: "entityType/DELETE",
	SET_CURRENT_PAGE: "entityType/SET_CURRENT_PAGE",
	SET_LIMIT: "entityType/SET_LIMIT",
	SET_SORTING: "entityType/SET_SORTING",
	SET_SEARCH_TEXT: "entityType/SET_SEARCH_TEXT",
	SET_FILTERS: "entityType/SET_FILTERS",
	RESET_FILTERS: "entityType/RESET_FILTERS",
};

export default actions;
