const actions = {
  SET_STATE: "userApprovalRequest/SET_STATE",
  GET_LIST: "userApprovalRequest/GET_LIST",
  GET_DOC: "userApprovalRequest/GET_DOC",
  CREATE: "userApprovalRequest/CREATE",
  UPDATE: "userApprovalRequest/UPDATE",
  DELETE: "userApprovalRequest/DELETE",
  APPROVE: "userApprovalRequest/APPROVE",
  REJECT: "userApprovalRequest/REJECT",
  SET_CURRENT_PAGE: "userApprovalRequest/SET_CURRENT_PAGE",
  SET_LIMIT: "userApprovalRequest/SET_LIMIT",
  SET_SORTING: "userApprovalRequest/SET_SORTING",
  SET_SEARCH_TEXT: "userApprovalRequest/SET_SEARCH_TEXT",
  SET_FILTERS: "userApprovalRequest/SET_FILTERS",
  RESET_FILTERS: "userApprovalRequest/RESET_FILTERS",
};

export default actions;
