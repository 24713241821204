import apiClient from "../axios";

// DocFolder - List
export const getList =
	async (/* queries: { start: number; length: number } */) =>
		apiClient
			.get(`/documentation/folders`, {
				/* headers: { "cache-control": "no-cache" } */
			})
			.then((res) => (res && res.data ? res.data : false))
			.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/documentation/folders/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create a doc folder
export const create = async (data: any) =>
	apiClient
		.post("/documentation/folders", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update a doc folder
export const update = async (data: any) =>
	apiClient
		.put(`/documentation/folders/${data.id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Delete a doc folder
export const deleteDocFolder = async (data: {
	reason: string;
	id: string | number;
}) =>
	apiClient
		.delete(`/documentation/folders/${data.id}`, { data })
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
