import apiClient from "../axios";

// Units - List
export const getUnits = async () =>
	apiClient
		.get("/misc/units", {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Offices - List
export const getOffices = async () =>
	apiClient
		.get("/misc/offices", {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Currencies - List
export const getCurrencies = async () =>
	apiClient
		.get("/misc/currencies", {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
