import apiClient from "../axios";

// Maintenance Rating - List
export const getList =
	async (/* queries: { start: number; length: number } */) =>
		apiClient
			.get(`/maintenance/ratings`, {
				/* headers: { "cache-control": "no-cache" } */
			})
			.then((res) => (res && res.data ? res.data : false))
			.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/maintenance/ratings/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create an maintenance/ratings
export const create = async (data: any) =>
	apiClient
		.post("/maintenance/ratings", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update an maintenance/ratings
export const update = async (data: any) =>
	apiClient
		.put(`/maintenance/ratings/${data.id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Activate an maintenance/ratings
export const activate = async (data: any) =>
	apiClient
		.patch(`/maintenance/ratings/${data.id}/activate`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Deactivate an maintenance/ratings
export const deactivate = async (data: any) =>
	apiClient
		.patch(`/maintenance/ratings/${data.id}/deactivate`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
