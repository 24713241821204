const actions = {
	SET_STATE: "additionalService/SET_STATE",
	GET_LIST: "additionalService/GET_LIST",
	GET_DOC: "additionalService/GET_DOC",
	CREATE: "additionalService/CREATE",
	UPDATE: "additionalService/UPDATE",
	DELETE: "additionalService/DELETE",
	APPROVE: "additionalService/APPROVE",
	REJECT: "additionalService/REJECT",
	SET_CURRENT_PAGE: "additionalService/SET_CURRENT_PAGE",
	SET_LIMIT: "additionalService/SET_LIMIT",
	SET_SORTING: "additionalService/SET_SORTING",
	SET_SEARCH_TEXT: "additionalService/SET_SEARCH_TEXT",
	SET_FILTERS: "additionalService/SET_FILTERS",
	RESET_FILTERS: "additionalService/RESET_FILTERS",
};

export default actions;
