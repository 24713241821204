const actions = {
	SET_STATE: "vendorBankAcc/SET_STATE",
	GET_LIST: "vendorBankAcc/GET_LIST",
	GET_DOC: "vendorBankAcc/GET_DOC",
	CREATE: "vendorBankAcc/CREATE",
	UPDATE: "vendorBankAcc/UPDATE",
	SET_CURRENT_PAGE: "vendorBankAcc/SET_CURRENT_PAGE",
	SET_LIMIT: "vendorBankAcc/SET_LIMIT",
	SET_SORTING: "vendorBankAcc/SET_SORTING",
	SET_SEARCH_TEXT: "vendorBankAcc/SET_SEARCH_TEXT",
	SET_FILTERS: "vendorBankAcc/SET_FILTERS",
	RESET_FILTERS: "vendorBankAcc/RESET_FILTERS",
	SET_ACTIVE_VENDOR: "vendorBankAcc/SET_ACTIVE_VENDOR",
};

export default actions;
