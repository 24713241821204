import { all, takeEvery, put, select, call } from "redux-saga/effects";
import { notification } from "antd";
import store from "store";

import { IDispatchObj, IServerResponse } from "@interfaces/custom";
import {
	account,
	uploadProfilePic,
	refreshToken,
	changePassword,
	activate,
	activateFacility,
	resetPassword,
	resetPasswordFacility,
} from "@services/api/account";
import {
	signin,
	signup,
	passwordResetRequest,
	resendPasswordResetRequest,
	verifyAccountWithCode,
	verifyAccountWithToken,
	logout,
	passwordResetability,
} from "@services/api/auth";
import actions from "./actions";
import { history } from "../store";

export function* SET_ACCOUNT({ payload }: IDispatchObj) {
	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingUser: true,
		},
	});

	const success: { _id: string }[] | false = yield call(account);

	if (success) {
		yield put({
			type: actions.SET_STATE,
			payload: {
				user: success,
			},
		});
	}

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingUser: false,
		},
	});
}

/**
 *
 *  PROFILE_PIC
 */

/* export function* SET_PROFILE_PIC({ payload }: IDispatchObj) {
	const { navigate } = payload;
	const { loading } = yield select((state) => state.account);

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingAction: true,
		},
	});

	const success: ISignupSuccess | false = yield call(uploadProfilePic, {
		files: payload.files,
	});

	console.log(success);

	if (success) {
		if (!success.message) {
			navigate("/auth/signin");
		} else {
			yield put({
				type: actions.SET_ACCOUNT,
				payload: {},
			});
		}
	}

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingAction: false,
		},
	});
}
 */
/**
 *
 *  SIGNIN
 */

export function* SIGNIN({ payload }: IDispatchObj) {
	const { resetStates, navigate, location } = payload;

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingAction: true,
		},
	});

	const success: { access_token: string } | false = yield call(signin, {
		email: payload.email,
		password: payload.password,
		remember_me: payload.rememberMe,
	});

	if (success) {
		store.set("accessToken", success.access_token);

		yield put({
			type: actions.SET_ACCOUNT,
			payload: {},
		});

		yield put({
			type: actions.SET_STATE,
			payload: {
				authorized: true,
			},
		});

		if (resetStates) {
			resetStates();
		}

		// console.log(location.state);

		if (location?.state?.lastPathname) {
			navigate(location.state.lastPathname);
		} else {
			navigate("/");
		}
	}

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingAction: false,
		},
	});
}

export function* SIGNUP({ payload }: IDispatchObj) {
	const { resetStates, navigate } = payload;

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingAction: true,
		},
	});

	const success: { data: any } | false = yield call(signup, payload);

	if (success) {
		notification.success({
			message: "Success",
			description: "Account created",
		});

		if (resetStates) {
			resetStates();
		}

		navigate("/auth/signin");
	}

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingAction: false,
		},
	});
}

export function* PASSWORD_RESET_REQUEST({ payload }: IDispatchObj) {
	const { resetStates } = payload;

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingAction: true,
		},
	});

	const success: { message: string } | false = yield call(
		passwordResetRequest,
		{
			email: payload.email,
		}
	);

	if (success) {
		if (resetStates) {
			resetStates();
		}

		notification.success({
			message: "An email has been sent to you",
		});
	}

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingAction: false,
		},
	});
}

/**
 *
 * LOGOUT
 */
export function* LOGOUT({ payload }: IDispatchObj) {
	if (payload?.setLoading) payload.setLoading(true);

	const success: { message: string } | false = yield call(logout);

	if (success) {
		yield put({
			type: actions.SET_STATE,
			payload: {
				authorized: false,
			},
		});
	}

	if (payload?.setLoading) payload.setLoading(false);
	if (payload?.onFinish) payload.onFinish();
	if (payload?.navigate) payload.navigate("/auth/signin");
}

/**
 *
 * REGISTRATION
 */
/* export function* REGISTER({ payload }: { payload: any }) {
	const { resetStates, navigate } = payload;

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingAction: true,
		},
	});

	const signupSucess: ISignupSuccess | false = yield call(signup, {
		name: payload.name,
		email: payload.email,
		password: payload.password,
		telephone: payload.phoneNumber,
		confirmPassword: payload.confirm,
		gender: payload.gender,
		dateOfBirth: payload.birthDate,
		nextOfKin: payload.nok,
		nextOfKinTelephone: payload.nokPhone,
		address: payload.address,
	});
	console.log(signupSucess);
	if (signupSucess) {
		console.log("signup success");
		store.set("message", signupSucess.message);

		yield put({
			type: actions.SET_ACCOUNT,
			payload: {},
		});

		if (resetStates) {
			resetStates();
		}

		navigate("/auth/register-verification");
	}

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingAction: false,
		},
	});
} */

/**
 *
 * REGISTRATION VERIFICATION
 */
/* export function* REGISTER_VERIFICATION({ payload }: IDispatchObj) {
	const { resetStates, navigate } = payload;
	const { loading } = yield select((state) => state.account);

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingAction: true,
		},
	});

	const registerSuccess: IRegisterSuccess | false = yield call(
		registerVerification,
		{
			code: payload.user,
		}
	);

	if (registerSuccess) {
		if (resetStates) {
			resetStates();
		}

		navigate("/auth/signin");
	} else {
		navigate("/error401");
	}

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingAction: false,
		},
	});
} */

/**
 *
 * RESET PASSWORD
 */
/* export function* RESET_PASSWORD({ payload }: IDispatchObj) {
	const { resetForm, navigate } = payload;
	const { loading } = yield select((state) => state.account);

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingAction: true,
		},
	});

	const resetPassword: IPasswordReset | false = yield call(passwordReset, {
		password: payload.password,
		confirmPassword: payload.confirm,
	});

	if (resetPassword) {
		if (!resetPassword.result) {
			navigate("/auth/signin");
		} else {
			store.set("result", resetPassword.result);

			yield put({
				type: actions.SET_ACCOUNT,
				payload: {},
			});

			navigate("/auth/signin");
		}
	}
	if (resetForm) {
		resetForm();
	}

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingAction: false,
		},
	});
} */

/**
 * UPDATE ACCOUNT
 */

/* export function* UPDATE_ACCOUNT_DETAILS({ payload }: IDispatchObj) {
	const { resetForm, navigate } = payload;

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingAction: true,
		},
	});

	const changeName: IUpdateAccountSuccess | false = yield call(
		updateAccountDetails,
		{
			name: payload.name,
			telephone: payload.telephone,
		}
	);

	if (changeName) {
		yield put({
			type: actions.SET_ACCOUNT,
			payload: {},
		});
	}

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingAction: false,
		},
	});
} */

/**
 *
 * EDIT/CHANGE PASSWORD
 */
export function* CHANGE_PASSWORD({ payload }: IDispatchObj) {
	if (payload.setLoading) payload.setLoading(true);

	const success: { message: string } | false = yield call(
		changePassword,
		payload
	);

	if (success) {
		notification.success({
			message: "Success",
			description: "Password successfully changed",
		});

		yield put({
			type: actions.SET_STATE,
			payload: { changePasswordModal: false },
		});

		yield put({
			type: actions.LOGOUT,
			payload: {},
		});

		if (payload.onSuccess) payload.onSuccess();
	}

	if (payload.setLoading) payload.setLoading(false);
	if (payload.onFinish) payload.onFinish();
}

/**
 *
 * REQUEST NEW PASSWORD
 */
/* export function* PASSWORD_REQUEST({ payload }: IDispatchObj) {
	const { resetForm, navigate } = payload;
	const { loading } = yield select((state) => state.account);

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingAction: true,
		},
	});

	const requestPassword: IPasswordRequest | false = yield call(
		passwordRequest,
		{
			email: payload.email,
		}
	);
	if (requestPassword) {
		if (!requestPassword.accessToken) {
			// navigate("/auth/reset-password");
			console.log("success");
		} else {
			store.set("accessToken", requestPassword.accessToken);

			yield put({
				type: actions.SET_ACCOUNT,
				payload: {},
			});
		}
	}

	if (resetForm) {
		resetForm();
	}

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingAction: false,
		},
	});
} */

export function* ACTIVATE({ payload }: IDispatchObj) {
	if (payload.setLoading) payload.setLoading(true);

	const res: IServerResponse | undefined = yield call(activate, payload);

	if (res) {
		notification.success({
			message: "success",
			description: "Account Activated",
		});

		if (payload.onSuccess) payload.onSuccess();
	}

	if (payload.setLoading) payload.setLoading(false);
	if (payload.onFinish) payload.onFinish();
}

export function* ACTIVATE_FACILITY({ payload }: IDispatchObj) {
	if (payload.setLoading) payload.setLoading(true);

	const res: IServerResponse | undefined = yield call(
		activateFacility,
		payload
	);

	if (res) {
		notification.success({
			message: "success",
			description: "Account Activated",
		});

		if (payload.onSuccess) payload.onSuccess();
	}

	if (payload.setLoading) payload.setLoading(false);
	if (payload.onFinish) payload.onFinish();
}

export function* RESET_PASSWORD({ payload }: IDispatchObj) {
	if (payload.setLoading) payload.setLoading(true);

	const res: IServerResponse | undefined = yield call(resetPassword, payload);

	if (res) {
		notification.success({
			message: "success",
			description: "Account Password Successfully Reset",
		});

		if (payload.onSuccess) payload.onSuccess();
	}

	if (payload.setLoading) payload.setLoading(false);
	if (payload.onFinish) payload.onFinish();
}

export function* RESET_PASSWORD_FACILITY({ payload }: IDispatchObj) {
	if (payload.setLoading) payload.setLoading(true);

	const res: IServerResponse | undefined = yield call(
		resetPasswordFacility,
		payload
	);

	if (res && res.status) {
		notification.success({
			message: "success",
			description: "Account Password Successfully Reset",
		});

		if (payload.onSuccess) payload.onSuccess();
	}

	if (payload.setLoading) payload.setLoading(false);
	if (payload.onFinish) payload.onFinish();
}

export default function* rootSaga() {
	yield all([
		takeEvery<string, any>(actions.SET_ACCOUNT, SET_ACCOUNT),
		takeEvery<string, any>(actions.SIGNIN, SIGNIN),
		takeEvery<string, any>(actions.SIGNUP, SIGNUP),
		takeEvery<string, any>(
			actions.PASSWORD_RESET_REQUEST,
			PASSWORD_RESET_REQUEST
		),
		// takeEvery<string, any>(
		// 	actions.UPDATE_ACCOUNT_DETAILS,
		// 	UPDATE_ACCOUNT_DETAILS
		// ),
		takeEvery<string, any>(actions.CHANGE_PASSWORD, CHANGE_PASSWORD),
		takeEvery<string, any>(actions.LOGOUT, LOGOUT),
		takeEvery<string, any>(actions.ACTIVATE, ACTIVATE),
		takeEvery<string, any>(actions.ACTIVATE_FACILITY, ACTIVATE_FACILITY),
		takeEvery<string, any>(actions.RESET_PASSWORD, RESET_PASSWORD),
		takeEvery<string, any>(
			actions.RESET_PASSWORD_FACILITY,
			RESET_PASSWORD_FACILITY
		),
	]);
}
