import "./styles/vendors/antd/themes/default.less";
// import './styles/vendors/antd/themes/dark.less'
import "./global.scss";

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ConfigProvider } from "antd";

import Router from "./router";
import store, { persistor } from "./redux/store";
import theme from "./theme";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.Suspense>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ConfigProvider theme={theme}>
					<Router />
				</ConfigProvider>
			</PersistGate>
		</Provider>
	</React.Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
