const actions = {
	SET_STATE: "account/SET_STATE",
	SET_ACCOUNT: "account/SET_ACCOUNT",
	SIGNIN: "account/SIGNIN",
	SIGNUP: "account/SIGNUP",
	PASSWORD_RESET_REQUEST: "account/PASSWORD_RESET_REQUEST",
	CHANGE_PASSWORD: "account/CHANGE_PASSWORD",
	ACTIVATE: "account/ACTIVATE",
	ACTIVATE_FACILITY: "account/ACTIVATE_FACILITY",
	RESET_PASSWORD: "account/RESET_PASSWORD",
	RESET_PASSWORD_FACILITY: "account/RESET_PASSWORD_FACILITY",
	LOGOUT: "account/LOGOUT",
};

export default actions;
