const actions = {
	SET_STATE: "vendor/SET_STATE",
	GET_LIST: "vendor/GET_LIST",
	GET_DOC: "vendor/GET_DOC",
	CREATE: "vendor/CREATE",
	UPDATE: "vendor/UPDATE",
	DELETE: "vendor/DELETE",
	ACTIVATE: "vendor/ACTIVATE",
	DEACTIVATE: "vendor/DEACTIVATE",
	SET_CURRENT_PAGE: "vendor/SET_CURRENT_PAGE",
	SET_LIMIT: "vendor/SET_LIMIT",
	SET_SORTING: "vendor/SET_SORTING",
	SET_SEARCH_TEXT: "vendor/SET_SEARCH_TEXT",
	SET_FILTERS: "vendor/SET_FILTERS",
	RESET_FILTERS: "vendor/RESET_FILTERS",
};

export default actions;
