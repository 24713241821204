const actions = {
	SET_STATE: "companyContract/SET_STATE",
	GET_LIST: "companyContract/GET_LIST",
	SET_CURRENT_PAGE: "companyContract/SET_CURRENT_PAGE",
	SET_LIMIT: "companyContract/SET_LIMIT",
	SET_SORTING: "companyContract/SET_SORTING",
	SET_SEARCH_TEXT: "companyContract/SET_SEARCH_TEXT",
	SET_FILTERS: "companyContract/SET_FILTERS",
	RESET_FILTERS: "companyContract/RESET_FILTERS",
	SET_ACTIVE_COMPANY: "companyContract/SET_ACTIVE_COMPANY",
};

export default actions;
