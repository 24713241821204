import apiClient from "../axios";

// get account data
export const account = async () =>
	apiClient
		.get("/auth/me", {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// refresh token
export const refreshToken = async () =>
	apiClient
		.get("/auth/refresh", {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// change password
export const changePassword = async (data: {
	password: string;
	password_confirmation: string;
	old_password: string;
}) =>
	apiClient
		.post("/auth/change-password", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// upload profilePic
export const uploadProfilePic = async (data: { files: any }) => {
	const fileData = new FormData();
	fileData.append("file", data.files, data.files.name);
	return apiClient
		.post("/user/set-profile-picture", fileData)
		.then((res) => {
			console.log(res);
			return res && res.data ? res.data : false;
		})
		.catch((err) => console.log(err));
};

// activate
export const activate = async (data: {
	password: string;
	confirmPassword: string;
}) =>
	apiClient
		.post("/account/activate", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// activate facility
export const activateFacility = async (data: {
	password: string;
	confirmPassword: string;
}) =>
	apiClient
		.post("/account/activate-facility", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// resetPassword
export const resetPassword = async (data: {
	password: string;
	confirmPassword: string;
}) =>
	apiClient
		.post("/account/reset-password", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// resetPassword facility
export const resetPasswordFacility = async (data: {
	password: string;
	confirmPassword: string;
}) =>
	apiClient
		.post("/account/reset-password-facility", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
