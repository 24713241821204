import apiClient from "../axios";

// Sign in
export const signin = async (data: {
	email: string;
	password: string;
	remember_me: boolean;
}) =>
	apiClient
		.post("/auth/login", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

export const signup = async (data: { email: string; password: string }) =>
	apiClient
		.post("/auth/register", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// request password reset link
export const passwordResetRequest = async (data: { email: string }) =>
	apiClient
		.post("/auth/send-password-reset-link", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// resend request password reset link
export const resendPasswordResetRequest = async (data: { email: string }) =>
	apiClient
		.post("/auth/resend-verification-code", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// verify account with token
export const verifyAccountWithToken = async (token: string) =>
	apiClient
		.get(`/auth/account-verification/token/${token}`)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// verify account with token
export const verifyAccountWithCode = async (code: string) =>
	apiClient
		.get(`/auth/account-verification/code/${code}`)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// logout
export const logout = async () =>
	apiClient
		.post("/auth/logout")
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// password resetability
export const passwordResetability = async (token: string) =>
	apiClient
		.get(`/auth/password-resetability/${token}`)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
