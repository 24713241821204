const actions = {
	SET_STATE: "docInsuranceCert/SET_STATE",
	GET_LIST: "docInsuranceCert/GET_LIST",
	GET_DOC: "docInsuranceCert/GET_DOC",
	CREATE: "docInsuranceCert/CREATE",
	UPDATE: "docInsuranceCert/UPDATE",
	DELETE: "docInsuranceCert/DELETE",
	APPROVE: "docInsuranceCert/APPROVE",
	REJECT: "docInsuranceCert/REJECT",
	SET_CURRENT_PAGE: "docInsuranceCert/SET_CURRENT_PAGE",
	SET_LIMIT: "docInsuranceCert/SET_LIMIT",
	SET_SORTING: "docInsuranceCert/SET_SORTING",
	SET_SEARCH_TEXT: "docInsuranceCert/SET_SEARCH_TEXT",
	SET_FILTERS: "docInsuranceCert/SET_FILTERS",
	RESET_FILTERS: "docInsuranceCert/RESET_FILTERS",
};

export default actions;
