const actions = {
	SET_STATE: "fueling/SET_STATE",
	GET_LIST: "fueling/GET_LIST",
	GET_DOC: "fueling/GET_DOC",
	CREATE: "fueling/CREATE",
	UPDATE: "fueling/UPDATE",
	DELETE: "fueling/DELETE",
	APPROVE: "fueling/APPROVE",
	REJECT: "fueling/REJECT",
	SET_CURRENT_PAGE: "fueling/SET_CURRENT_PAGE",
	SET_LIMIT: "fueling/SET_LIMIT",
	SET_SORTING: "fueling/SET_SORTING",
	SET_SEARCH_TEXT: "fueling/SET_SEARCH_TEXT",
	SET_FILTERS: "fueling/SET_FILTERS",
	RESET_FILTERS: "fueling/RESET_FILTERS",
};

export default actions;
