const actions = {
	SET_STATE: "bookingInvoice/SET_STATE",
	GET_LIST: "bookingInvoice/GET_LIST",
	GET_DOC: "bookingInvoice/GET_DOC",
	CREATE: "bookingInvoice/CREATE",
	UPDATE: "bookingInvoice/UPDATE",
	DELETE: "bookingInvoice/DELETE",
	APPROVE: "bookingInvoice/APPROVE",
	REJECT: "bookingInvoice/REJECT",
	SET_CURRENT_PAGE: "bookingInvoice/SET_CURRENT_PAGE",
	SET_LIMIT: "bookingInvoice/SET_LIMIT",
	SET_SORTING: "bookingInvoice/SET_SORTING",
	SET_SEARCH_TEXT: "bookingInvoice/SET_SEARCH_TEXT",
	SET_FILTERS: "bookingInvoice/SET_FILTERS",
	RESET_FILTERS: "bookingInvoice/RESET_FILTERS",
};

export default actions;
