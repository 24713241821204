import apiClient from "../axios";

// Units - List
export const getList =
	async (/* queries: { start: number; length: number } */) =>
		apiClient
			.get(`/units`, {
				/* headers: { "cache-control": "no-cache" } */
			})
			.then((res) => (res && res.data ? res.data : false))
			.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/units/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create an office
export const create = async (data: { name: string; desc?: boolean }) =>
	apiClient
		.post("/units", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update an office
export const update = async (data: {
	name?: string;
	desc?: boolean;
	id: string | number;
}) =>
	apiClient
		.put(`/units/${data.id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Activate an office
export const activate = async (data: { reason: string; id: string | number }) =>
	apiClient
		.patch(`/units/${data.id}/activate`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Deactivate an office
export const deactivate = async (data: {
	reason: string;
	id: string | number;
}) =>
	apiClient
		.patch(`/units/${data.id}/deactivate`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Change HOD an office
export const changeHOD = async (data: {
	id: string | number;
	user_id: string | number;
}) =>
	apiClient
		.patch(`/units/${data.id}/change-hod`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
