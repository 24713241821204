import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import TweenOne from "rc-tween-one";
import QueueAnim from "rc-queue-anim";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";

import { getChildrenToRender, isImg } from "utils/misc";

const Footer = ({ ...props }: any) => {
	const getLiChildren = (data: any) =>
		data.map((item: any, i: any) => {
			const { title, childWrapper, ...itemProps } = item;

			return (
				<Col key={i} {...itemProps} title={null} content={null}>
					<h5 {...title}>
						{(typeof title.children === "string" &&
							title.children.match(isImg)) ||
						title.children.split("")[0] === "/" ? (
							<img src={title.children} height={40} alt="img" />
						) : (
							title.children
						)}
					</h5>
					<div {...childWrapper}>
						{childWrapper.children.map(getChildrenToRender)}
					</div>
				</Col>
			);
		});

	const { dataSource } = props;
	delete props.dataSource;
	delete props.isMobile;
	const childrenToRender = getLiChildren(dataSource.block.children);

	return (
		<div {...props} {...dataSource.wrapper}>
			<OverPack {...dataSource.OverPack}>
				<QueueAnim
					type="bottom"
					key="ul"
					leaveReverse
					component={Row}
					{...dataSource.block}
				>
					{childrenToRender}
				</QueueAnim>
				<TweenOne
					animation={{ y: "+=30", opacity: 0, type: "from" }}
					key="copyright"
					{...dataSource.copyrightWrapper}
				>
					<div {...dataSource.copyrightPage}>
						<div {...dataSource.copyright}>{dataSource.copyright.children}</div>
					</div>
				</TweenOne>
			</OverPack>
		</div>
	);
};

export default Footer;
