import actions from "./actions";

const initialState = {
  list: [],
  total: 0,
  totalFiltered: 0,
  page: 1,
  length: 12,
  filterStatus: "",
  doc: {},
  updateModal: false,
  createModal: false,
  deleteModal: false,
  rejectModal: false,
  loadingList: false,
  loadingDoc: false,
};

export default function userApprovalRequestReducer(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
