import { all } from "redux-saga/effects";
import account from "./account/sagas";
import unit from "./unit/sagas";
import staff from "./staff/sagas";
import staffPermission from "./staffPermission/sagas";
import office from "./office/sagas";
import menu from "./menu/sagas";
import settings from "./settings/sagas";
import driver from "./driver/sagas";
import vehicle from "./vehicle/sagas";
import vehicleType from "./vehicleType/sagas";
import vehicleGroup from "./vehicleGroup/sagas";
import vehicleMake from "./vehicleMake/sagas";
import vehicleService from "./vehicleService/sagas";
import driverVehicle from "./driverVehicle/sagas";
import vendor from "./vendor/sagas";
import batterySpec from "./batterySpec/sagas";
import company from "./company/sagas";
import contactPerson from "./contactPerson/sagas";
import docAssetType from "./docAssetType/sagas";
import docFolder from "./docFolder/sagas";
import docType from "./docType/sagas";
import entityType from "./entityType/sagas";
import fueling from "./fueling/sagas";
import fuelingType from "./fuelingType/sagas";
import fillingStation from "./fillingStation/sagas";
import fuelType from "./fuelType/sagas";
import contractType from "./contractType/sagas";
import contract from "./contract/sagas";
import serviceType from "./serviceType/sagas";
import reservation from "./reservation/sagas";
import trip from "./trip/sagas";
import tripStatus from "./tripStatus/sagas";
import invoiceStatus from "./invoiceStatus/sagas";
import additionalService from "./additionalService/sagas";
import cost from "./cost/sagas";
import userApprovalRequest from "./userApprovalRequest/sagas";
import bookingInvoice from "./bookingInvoice/sagas";
import assetSupplier from "./assetSupplier/sagas";
import location from "./location/sagas";
import tyreSpec from "./tyreSpec/sagas";
import vehicleSupplier from "./vehicleSupplier/sagas";
import financier from "./financier/sagas";
import transmissionType from "./transmissionType/sagas";
import docAsset from "./docAsset/sagas";
import docInsuranceCert from "./docInsuranceCert/sagas";
import vendorBankAcc from "./vendorBankAcc/sagas";
import driverLicence from "./driverLicence/sagas";
import accountNumber from "./accountNumber/sagas";
import misc from "./misc/sagas";
import maintenanceWorkshop from "./maintenanceWorkshop/sagas";
import maintenanceRating from "./maintenanceRating/sagas";
import maintenanceType from "./maintenanceType/sagas";
import maintenanceSubType from "./maintenanceSubType/sagas";
import maintenanceFaultType from "./maintenanceFaultType/sagas";
import companyContract from "./companyContract/sagas";
import companyVehicle from "./companyVehicle/sagas";
// other redux types implemented will be imported here

export default function* rootSaga() {
	// all redux types imported will be called here, seperated by comas
	yield all([
		account(),
		unit(),
		staff(),
		staffPermission(),
		office(),
		menu(),
		settings(),
		driver(),
		vehicle(),
		vehicleType(),
		vehicleGroup(),
		vehicleMake(),
		vehicleService(),
		driverVehicle(),
		vendor(),
		batterySpec(),
		company(),
		contactPerson(),
		docAssetType(),
		docFolder(),
		docType(),
		entityType(),
		fueling(),
		fuelingType(),
		fillingStation(),
		contractType(),
		contract(),
		serviceType(),
		assetSupplier(),
		reservation(),
		trip(),
		tripStatus(),
		invoiceStatus(),
		additionalService(),
		cost(),
		userApprovalRequest(),
		bookingInvoice(),
		fuelType(),
		location(),
		tyreSpec(),
		vehicleSupplier(),
		financier(),
		transmissionType(),
		docAsset(),
		docInsuranceCert(),
		vendorBankAcc(),
		driverLicence(),
		accountNumber(),
		misc(),
		maintenanceWorkshop(),
		maintenanceRating(),
		maintenanceType(),
		maintenanceSubType(),
		maintenanceFaultType(),
		companyContract(),
		companyVehicle(),
	]);
}
