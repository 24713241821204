import apiClient from "../axios";

// DocDoc - List
export const getList =
	async (/* queries: { start: number; length: number } */) =>
		apiClient
			.get(`/documentation/asset-docs`, {
				/* headers: { "cache-control": "no-cache" } */
			})
			.then((res) => (res && res.data ? res.data : false))
			.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/documentation/asset-docs/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create a doc asset-doc
export const create = async (data: any) =>
	apiClient
		.post("/documentation/asset-docs", data.formData, {
			headers: { "Content-Type": "multipart/form-data" },
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update a doc asset-doc
export const update = async (data: any) =>
	apiClient
		.put(`/documentation/asset-docs/${data.id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Delete a doc asset-doc
export const deleteDocAssetDoc = async (data: {
	reason: string;
	id: string | number;
}) =>
	apiClient
		.delete(`/documentation/asset-docs/${data.id}`, { data })
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Get Uploads
export const getDocUploads = async (id: string) =>
	apiClient
		.get(`/documentation/asset-docs/${id}/files`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Add an upload
export const addUploadFile = async (data: any) =>
	apiClient
		.post(`/documentation/asset-docs/${data.id}/files`, data.formData, {
			headers: { "Content-Type": "multipart/form-data" },
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Delete an upload
export const deleteUpload = async (data: any) =>
	apiClient
		.delete(`/documentation/asset-docs/${data.id}/files/${data.file_id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
