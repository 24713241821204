const actions = {
	SET_STATE: "invoiceStatus/SET_STATE",
	GET_LIST: "invoiceStatus/GET_LIST",
	GET_DOC: "invoiceStatus/GET_DOC",
	CREATE: "invoiceStatus/CREATE",
	UPDATE: "invoiceStatus/UPDATE",
	DELETE: "invoiceStatus/DELETE",
	APPROVE: "invoiceStatus/APPROVE",
	REJECT: "invoiceStatus/REJECT",
	SET_CURRENT_PAGE: "invoiceStatus/SET_CURRENT_PAGE",
	SET_LIMIT: "invoiceStatus/SET_LIMIT",
	SET_SORTING: "invoiceStatus/SET_SORTING",
	SET_SEARCH_TEXT: "invoiceStatus/SET_SEARCH_TEXT",
	SET_FILTERS: "invoiceStatus/SET_FILTERS",
	RESET_FILTERS: "invoiceStatus/RESET_FILTERS",
};

export default actions;
