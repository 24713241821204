const actions = {
	SET_STATE: "driverLicence/SET_STATE",
	GET_LIST: "driverLicence/GET_LIST",
	GET_DOC: "driverLicence/GET_DOC",
	CREATE: "driverLicence/CREATE",
	UPDATE: "driverLicence/UPDATE",
	DELETE: "driverLicence/DELETE",
	APPROVE: "driverLicence/APPROVE",
	REJECT: "driverLicence/REJECT",
	SET_CURRENT_PAGE: "driverLicence/SET_CURRENT_PAGE",
	SET_LIMIT: "driverLicence/SET_LIMIT",
	SET_SORTING: "driverLicence/SET_SORTING",
	SET_SEARCH_TEXT: "driverLicence/SET_SEARCH_TEXT",
	SET_FILTERS: "driverLicence/SET_FILTERS",
	RESET_FILTERS: "driverLicence/RESET_FILTERS",
};

export default actions;
