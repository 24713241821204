import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Layout } from "antd";
import { enquireScreen } from "enquire-js";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

import Nav0 from "components/public/Nav";
import Footer1 from "components/public/Footer";

type PublicLayoutProps = {
	children: JSX.Element;
	history: any;
};

let isMobilee = false;

enquireScreen((b: any) => {
	isMobilee = b;
});

function PublicLayout({ children, history }: PublicLayoutProps) {
	const [isMobile, setIsMobile] = useState<boolean>(isMobilee);

	useEffect(() => {
		enquireScreen((b: any) => {
			setIsMobile(!!b);
		});
	}, []);

	return (
		<>
			<Helmet titleTemplate="%s | Groot" />
			<Layout>
				<Nav0
					id="Nav0_0"
					key="Nav0_0"
					dataSource={{
						wrapper: { className: "header0 home-page-wrapper" },
						page: { className: "home-page" },
						logo: {
							className: "header0-logo",
							children: `${process.env.PUBLIC_URL}/resources/images/logo-white.png`,
						},
						Menu: {
							className: "header0-menu",
							children: [
								{
									name: "home",
									className: "header0-item",
									children: {
										href: "/",
										children: [{ children: "Home", name: "text" }],
									},
								},
								{
									name: "company",
									className: "header0-item",
									children: {
										href: "/company",
										children: [{ children: "Company", name: "text" }],
									},
								},
								{
									name: "pricing",
									className: "header0-item",
									children: {
										href: "/pricing",
										children: [{ children: "Pricing", name: "text" }],
									},
								},
								{
									name: "contact",
									className: "header0-item",
									children: {
										href: "/contact",
										children: [{ children: "Contact", name: "text" }],
									},
								},
							],
						},
						mobileMenu: {
							className: "header0-mobile-menu",
							icon: <MenuOutlined style={{ fontSize: 12 }} />,
						},
					}}
					isMobile={isMobile}
				/>
				<Layout.Content style={{ minHeight: "90vh" }}>
					{children}
				</Layout.Content>
				<Footer1
					id="Footer1_0"
					key="Footer1_0"
					dataSource={{
						wrapper: { className: "home-page-wrapper footer1-wrapper" },
						OverPack: { className: "footer1", playScale: 0.2 },
						block: {
							className: "home-page",
							gutter: 0,
							children: [
								{
									name: "block0",
									xs: 24,
									md: 6,
									className: "block",
									title: {
										className: "logo",
										children: `${process.env.PUBLIC_URL}/resources/images/logo-white.png`,
									},
									childWrapper: {
										className: "slogan",
										children: [
											{
												name: "content0",
												children: "Groot Facility Manager.",
											},
										],
									},
								},
								{
									name: "block1",
									xs: 24,
									md: 6,
									className: "block",
									title: { children: "products" },
									childWrapper: {
										children: [
											{
												name: "link0",
												href: "#",
												to: "pricing",
												children: "Pricing",
											},
											{
												name: "link1",
												href: "#",
												to: "contact",
												children: "Support",
											},
											{ name: "link2", href: "#", to: "#", children: "API" },
										],
									},
								},
								{
									name: "block2",
									xs: 24,
									md: 6,
									className: "block",
									title: { children: "company" },
									childWrapper: {
										children: [
											{
												href: "#",
												name: "link0",
												to: "company",
												children: "About Us",
											},
											{
												href: "#",
												name: "link1",
												to: "company",
												children: "Careers",
											},
											{
												href: "#",
												name: "link1",
												to: "contact",
												children: "Contact Us",
											},
											{
												href: "#",
												name: "link1",
												to: "privacy-policy",
												children: "Legal Notices",
											},
											{
												href: "#",
												name: "link1",
												to: "privacy-policy",
												children: "Privacy",
											},
										],
									},
								},
								{
									name: "block3",
									xs: 24,
									md: 6,
									className: "block",
									title: { children: "socials" },
									childWrapper: {
										children: [
											{ href: "#", name: "link0", children: "LinkedIn" },
											{ href: "#", name: "link1", children: "Twitter" },
											{ href: "#", name: "link1", children: "Facebook" },
										],
									},
								},
							],
						},
						copyrightWrapper: { className: "copyright-wrapper" },
						copyrightPage: { className: "home-page" },
						copyright: {
							className: "copyright",
							children: (
								<span>
									{`©${dayjs().year()} by `}
									<Link to="/">Groot</Link> All Rights Reserved
								</span>
							),
						},
					}}
					isMobile={isMobile}
				/>
			</Layout>
		</>
	);
}

export default PublicLayout;
