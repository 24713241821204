const actions = {
	SET_STATE: "docAsset/SET_STATE",
	GET_LIST: "docAsset/GET_LIST",
	GET_DOC: "docAsset/GET_DOC",
	CREATE: "docAsset/CREATE",
	UPDATE: "docAsset/UPDATE",
	DELETE: "docAsset/DELETE",
	GET_MEDIA_LIST: "docAsset/GET_MEDIA_LIST",
	ADD_MEDIA: "docAsset/ADD_MEDIA",
	REMOVE_MEDIA: "docAsset/REMOVE_MEDIA",
	SET_CURRENT_PAGE: "docAsset/SET_CURRENT_PAGE",
	SET_LIMIT: "docAsset/SET_LIMIT",
	SET_SORTING: "docAsset/SET_SORTING",
	SET_SEARCH_TEXT: "docAsset/SET_SEARCH_TEXT",
	SET_FILTERS: "docAsset/SET_FILTERS",
	RESET_FILTERS: "docAsset/RESET_FILTERS",
};

export default actions;
