const actions = {
	SET_STATE: "contract/SET_STATE",
	GET_LIST: "contract/GET_LIST",
	GET_DOC: "contract/GET_DOC",
	CREATE: "contract/CREATE",
	UPDATE: "contract/UPDATE",
	DELETE: "contract/DELETE",
	CANCEL: "contract/CANCEL",
	TERMINATE: "contract/TERMINATE",
	EXPIRE: "contract/EXPIRE",
	RESERVE: "contract/RESERVE",
	SET_CURRENT_PAGE: "contract/SET_CURRENT_PAGE",
	SET_LIMIT: "contract/SET_LIMIT",
	SET_SORTING: "contract/SET_SORTING",
	SET_SEARCH_TEXT: "contract/SET_SEARCH_TEXT",
	SET_FILTERS: "contract/SET_FILTERS",
	RESET_FILTERS: "contract/RESET_FILTERS",
};

export default actions;
