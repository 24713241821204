const actions = {
	SET_STATE: "fillingStation/SET_STATE",
	GET_LIST: "fillingStation/GET_LIST",
	GET_DOC: "fillingStation/GET_DOC",
	CREATE: "fillingStation/CREATE",
	UPDATE: "fillingStation/UPDATE",
	DELETE: "fillingStation/DELETE",
	SET_CURRENT_PAGE: "fillingStation/SET_CURRENT_PAGE",
	SET_LIMIT: "fillingStation/SET_LIMIT",
	SET_SORTING: "fillingStation/SET_SORTING",
	SET_SEARCH_TEXT: "fillingStation/SET_SEARCH_TEXT",
	SET_FILTERS: "fillingStation/SET_FILTERS",
	RESET_FILTERS: "fillingStation/RESET_FILTERS",
};

export default actions;
