import apiClient from "../axios";

// assetSupplier - List
export const getList =
	async (/* queries: { start: number; length: number } */) =>
		apiClient
			.get(`/booking-trip-status`, {
				/* headers: { "cache-control": "no-cache" } */
			})
			.then((res) => (res && res.data ? res.data : false))
			.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/booking-trip-status/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create a booking-trip-status/
export const create = async (data: any) =>
	apiClient
		.post("/booking-trip-status", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update a booking-trip-status/
export const update = async (data: any) =>
	apiClient
		.put(`/booking-trip-status/${data.id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Delete a booking-trip-status/
export const deleteTripStatus = async (data: {
	reason: string;
	id: string | number;
}) =>
	apiClient
		.delete(`/booking-trip-status/${data.id}`, { data })
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
