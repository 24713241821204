import apiClient from "../axios";

// Maintenance Workshop - List
export const getList =
	async (/* queries: { start: number; length: number } */) =>
		apiClient
			.get(`/maintenance/workshops`, {
				/* headers: { "cache-control": "no-cache" } */
			})
			.then((res) => (res && res.data ? res.data : false))
			.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/maintenance/workshops/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create an maintenance/workshops
export const create = async (data: any) =>
	apiClient
		.post("/maintenance/workshops", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update an maintenance/workshops
export const update = async (data: any) =>
	apiClient
		.put(`/maintenance/workshops/${data.id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Activate an maintenance/workshops
export const activate = async (data: any) =>
	apiClient
		.patch(`/maintenance/workshops/${data.id}/activate`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Deactivate an maintenance/workshops
export const deactivate = async (data: any) =>
	apiClient
		.patch(`/maintenance/workshops/${data.id}/deactivate`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
