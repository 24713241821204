import apiClient from "../axios";

// Account Number - List
export const getList =
	async (/* queries: { start: number; length: number } */) =>
		apiClient
			.get(`/account-numbers`, {
				/* headers: { "cache-control": "no-cache" } */
			})
			.then((res) => (res && res.data ? res.data : false))
			.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/account-numbers/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create an account-numbers
export const create = async (data: any) =>
	apiClient
		.post("/account-numbers", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update an account-numbers
export const update = async (data: any) =>
	apiClient
		.put(`/account-numbers/${data.id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Activate an account-numbers
export const activate = async (data: any) =>
	apiClient
		.patch(`/account-numbers/${data.id}/activate`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Deactivate an account-numbers
export const deactivate = async (data: any) =>
	apiClient
		.patch(`/account-numbers/${data.id}/deactivate`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
