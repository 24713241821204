const actions = {
	SET_STATE: "contractType/SET_STATE",
	GET_LIST: "contractType/GET_LIST",
	GET_DOC: "contractType/GET_DOC",
	CREATE: "contractType/CREATE",
	UPDATE: "contractType/UPDATE",
	DELETE: "contractType/DELETE",
	APPROVE: "contractType/APPROVE",
	REJECT: "contractType/REJECT",
	SET_CURRENT_PAGE: "contractType/SET_CURRENT_PAGE",
	SET_LIMIT: "contractType/SET_LIMIT",
	SET_SORTING: "contractType/SET_SORTING",
	SET_SEARCH_TEXT: "contractType/SET_SEARCH_TEXT",
	SET_FILTERS: "contractType/SET_FILTERS",
	RESET_FILTERS: "contractType/RESET_FILTERS",
};

export default actions;
