const actions = {
	SET_STATE: "location/SET_STATE",
	GET_LIST: "location/GET_LIST",
	GET_DOC: "location/GET_DOC",
	CREATE: "location/CREATE",
	UPDATE: "location/UPDATE",
	DELETE: "location/DELETE",
	SET_CURRENT_PAGE: "location/SET_CURRENT_PAGE",
	SET_LIMIT: "location/SET_LIMIT",
	SET_SORTING: "location/SET_SORTING",
	SET_SEARCH_TEXT: "location/SET_SEARCH_TEXT",
	SET_FILTERS: "location/SET_FILTERS",
	RESET_FILTERS: "location/RESET_FILTERS",
};

export default actions;
