import actions from "./actions";

const initialState = {
	user: null,
	authorized: false, // default value
	ac: { grants: { some: true } },
	loadingUser: false,
	loadingAction: false,
	changePasswordModal: false,
	updateProfileModal: false,
};

export default function accountReducer(state = initialState, action: any) {
	switch (action.type) {
		case actions.SET_STATE:
			return { ...state, ...action.payload };
		default:
			return state;
	}
}
