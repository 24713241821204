const actions = {
	SET_STATE: "maintenanceType/SET_STATE",
	GET_LIST: "maintenanceType/GET_LIST",
	GET_DOC: "maintenanceType/GET_DOC",
	CREATE: "maintenanceType/CREATE",
	UPDATE: "maintenanceType/UPDATE",
	DELETE: "maintenanceType/DELETE",
	ACTIVATE: "maintenanceType/ACTIVATE",
	DEACTIVATE: "maintenanceType/DEACTIVATE",
	SET_CURRENT_PAGE: "maintenanceType/SET_CURRENT_PAGE",
	SET_LIMIT: "maintenanceType/SET_LIMIT",
	SET_SORTING: "maintenanceType/SET_SORTING",
	SET_SEARCH_TEXT: "maintenanceType/SET_SEARCH_TEXT",
	SET_FILTERS: "maintenanceType/SET_FILTERS",
	RESET_FILTERS: "maintenanceType/RESET_FILTERS",
};

export default actions;
