const actions = {
	SET_STATE: "docFolder/SET_STATE",
	GET_LIST: "docFolder/GET_LIST",
	GET_DOC: "docFolder/GET_DOC",
	CREATE: "docFolder/CREATE",
	UPDATE: "docFolder/UPDATE",
	DELETE: "docFolder/DELETE",
	SET_CURRENT_PAGE: "docFolder/SET_CURRENT_PAGE",
	SET_LIMIT: "docFolder/SET_LIMIT",
	SET_SORTING: "docFolder/SET_SORTING",
	SET_SEARCH_TEXT: "docFolder/SET_SEARCH_TEXT",
	SET_FILTERS: "docFolder/SET_FILTERS",
	RESET_FILTERS: "docFolder/RESET_FILTERS",
};

export default actions;
