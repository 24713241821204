const actions = {
	SET_STATE: "office/SET_STATE",
	GET_LIST: "office/GET_LIST",
	GET_PUBLIC_LIST: "office/GET_PUBLIC_LIST",
	GET_DOC: "office/GET_DOC",
	CREATE: "office/CREATE",
	UPDATE: "office/UPDATE",
	ACTIVATE: "office/ACTIVATE",
	DEACTIVATE: "office/DEACTIVATE",
	SET_CURRENT_PAGE: "office/SET_CURRENT_PAGE",
	SET_LIMIT: "office/SET_LIMIT",
	SET_SORTING: "office/SET_SORTING",
	SET_SEARCH_TEXT: "office/SET_SEARCH_TEXT",
	SET_FILTERS: "office/SET_FILTERS",
	RESET_FILTERS: "office/RESET_FILTERS",
};

export default actions;
