const actions = {
	SET_STATE: "vehicleType/SET_STATE",
	GET_LIST: "vehicleType/GET_LIST",
	GET_DOC: "vehicleType/GET_DOC",
	CREATE: "vehicleType/CREATE",
	UPDATE: "vehicleType/UPDATE",
	DELETE: "vehicleType/DELETE",
	SET_CURRENT_PAGE: "vehicleType/SET_CURRENT_PAGE",
	SET_LIMIT: "vehicleType/SET_LIMIT",
	SET_SORTING: "vehicleType/SET_SORTING",
	SET_SEARCH_TEXT: "vehicleType/SET_SEARCH_TEXT",
	SET_FILTERS: "vehicleType/SET_FILTERS",
	RESET_FILTERS: "vehicleType/RESET_FILTERS",
};

export default actions;
