import apiClient from "../axios";

// Contract - List
export const getList =
	async (/* queries: { start: number; length: number } */) =>
		apiClient
			.get(`/contracts`, {
				/* headers: { "cache-control": "no-cache" } */
			})
			.then((res) => (res && res.data ? res.data : false))
			.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/contracts/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create a Contract
export const create = async (data: any) =>
	apiClient
		.post("/contracts", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update a Contract
export const update = async (data: any) =>
	apiClient
		.put(`/contracts/${data.id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Delete a Contract
export const deleteContract = async (data: {
	reason: string;
	id: string | number;
}) =>
	apiClient
		.delete(`/contracts/${data.id}`, { data })
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Mark a Contract as Canceled
export const cancel = async (data: any) =>
	apiClient
		.patch(`/contracts/${data.id}/mark-as-canceled`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Mark a Contract as Terminated
export const terminate = async (data: any) =>
	apiClient
		.patch(`/contracts/${data.id}/mark-as-terminated`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Mark a Contract as Expired
export const expire = async (data: any) =>
	apiClient
		.patch(`/contracts/${data.id}/mark-as-expired`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Mark a Contract as Reserved
export const reserve = async (data: any) =>
	apiClient
		.patch(`/contracts/${data.id}/mark-as-reserved`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
