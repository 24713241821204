import { createElement } from "react";
import axios from "axios";
import store from "store";
import { history } from "@redux/store";
import { notification } from "antd";

// instance of a class
const apiClient = axios.create({
	baseURL: `/api`,
});

// middleware
apiClient.interceptors.request.use((request: any) => {
	const accessToken = store.get("accessToken");

	if (accessToken) {
		request.headers.Authorization = `Bearer ${accessToken}`;
		request.headers.accessToken = accessToken;
	}
	return request;
});

apiClient.interceptors.response.use(undefined, (error) => {
	// errors handling
	const { response } = error;
	const { data } = response;

	if (data) {
		if (data.error?.message?.message) {
			notification.warning({
				message: data.error.message.message,
			});
		} else if (data.message) {
			notification.warning({
				message: data.message,
			});
		} else if (data.data?.message) {
			notification.warning({
				message: data.data.message,
			});
		} else if (data.errors) {
			notification.warning({
				message: data.status,
				description: createElement(
					"ul",
					{},
					...Object.keys(data.errors).map((key: any) =>
						createElement("li", {}, data.errors[key])
					)
				),
			});
		} else {
			notification.warning({
				message: JSON.stringify(data),
			});
		}
	}

	if (
		[0, 401].includes(response.status) &&
		!window.location.pathname.startsWith("/auth")
	) {
		history.push("/auth/signin", { lastPathname: window.location.pathname });
	}
});

export default apiClient;
