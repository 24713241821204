import React from "react";
// import { BrowserRouter } from "react-router-dom";
// import { ConnectedRouter } from "connected-react-router";

import AnimatedRoutes from "./AnimatedRoutes";
import CustomRouter from "./CustomRouter";
import { history } from "./redux/store";

function Router() {
	return (
		<CustomRouter history={history}>
			{/* <Layout history={history}>
			 <BrowserRouter> */}
			<AnimatedRoutes />
			{/* </BrowserRouter>
		   </Layout> */}
		</CustomRouter>
	);
}

export default Router;
