const actions = {
	SET_STATE: "vehicleGroup/SET_STATE",
	GET_LIST: "vehicleGroup/GET_LIST",
	GET_DOC: "vehicleGroup/GET_DOC",
	CREATE: "vehicleGroup/CREATE",
	UPDATE: "vehicleGroup/UPDATE",
	DELETE: "vehicleGroup/DELETE",
	SET_CURRENT_PAGE: "vehicleGroup/SET_CURRENT_PAGE",
	SET_LIMIT: "vehicleGroup/SET_LIMIT",
	SET_SORTING: "vehicleGroup/SET_SORTING",
	SET_SEARCH_TEXT: "vehicleGroup/SET_SEARCH_TEXT",
	SET_FILTERS: "vehicleGroup/SET_FILTERS",
	RESET_FILTERS: "vehicleGroup/RESET_FILTERS",
};

export default actions;
