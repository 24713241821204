const actions = {
	SET_STATE: "contactPerson/SET_STATE",
	GET_LIST: "contactPerson/GET_LIST",
	GET_DOC: "contactPerson/GET_DOC",
	CREATE: "contactPerson/CREATE",
	UPDATE: "contactPerson/UPDATE",
	DELETE: "contactPerson/DELETE",
	SET_CURRENT_PAGE: "contactPerson/SET_CURRENT_PAGE",
	SET_LIMIT: "contactPerson/SET_LIMIT",
	SET_SORTING: "contactPerson/SET_SORTING",
	SET_SEARCH_TEXT: "contactPerson/SET_SEARCH_TEXT",
	SET_FILTERS: "contactPerson/SET_FILTERS",
	RESET_FILTERS: "contactPerson/RESET_FILTERS",
	SET_ACTIVE_COMPANY: "contactPerson/SET_ACTIVE_COMPANY",
};

export default actions;
