import {
	all,
	put,
	call,
	takeEvery,
	debounce,
	select,
} from "redux-saga/effects";
import { notification } from "antd";

import {
	getPermisisonList,
	addPermission,
	deletePermission,
} from "@services/api/staff";
import { IDispatchObj, IServerResponse } from "@interfaces/custom";
import actions from "./actions";

export function* GET_LIST() {
	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingList: true,
		},
	});

	// Get queries
	const { page, length, activeStaff } = yield select(
		(state) => state.staffPermission
	);

	const res:
		| { data: any[]; recordsTotal: number; recordsFiltered: number }
		| undefined = yield call(getPermisisonList, {
		/* start: (page - 1) * length, length */ id: activeStaff,
	});

	if (res && res.data) {
		yield put({
			type: actions.SET_STATE,
			payload: {
				list: res.data,
				// total: res.recordsTotal,
				// totalFiltered: res.recordsFiltered,
			},
		});
	}

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingList: false,
		},
	});
}

export function* ADD({ payload }: IDispatchObj) {
	if (payload.setLoading) payload.setLoading(true);

	const res: IServerResponse | undefined = yield call(addPermission, payload);

	if (res) {
		notification.success({
			message: "success",
			description: "New staff permissions added",
		});

		if (payload.onSuccess) payload.onSuccess();
	}

	if (payload.setLoading) payload.setLoading(false);
	if (payload.onFinish) payload.onFinish();
}

export function* DELETE({ payload }: IDispatchObj) {
	if (payload.setLoading) payload.setLoading(true);

	const res: IServerResponse | undefined = yield call(
		deletePermission,
		payload
	);

	if (res) {
		notification.success({
			message: "success",
			description: "staff permissions has been deleted",
		});

		if (payload.onSuccess) payload.onSuccess();
	}

	if (payload.setLoading) payload.setLoading(false);
	if (payload.onFinish) payload.onFinish();
}

export function* SET_CURRENT_PAGE({ payload }: IDispatchObj) {
	const { page } = payload;

	yield put({
		type: actions.SET_STATE,
		payload: {
			page,
		},
	});

	yield call(GET_LIST);
}

export function* SET_LIMIT({ payload }: IDispatchObj) {
	const { length } = payload;

	yield put({
		type: actions.SET_STATE,
		payload: {
			length,
		},
	});

	yield call(GET_LIST);
}

export function* SET_SORTING({ payload }: IDispatchObj) {
	const { sortOrder, sortCol } = payload;

	yield put({
		type: actions.SET_STATE,
		payload: {
			sortOrder,
			sortCol,
		},
	});

	yield call(GET_LIST);
}

export function* SET_SEARCH_TEXT({ payload }: IDispatchObj) {
	const { filterSearch } = payload;

	yield put({
		type: actions.SET_STATE,
		payload: {
			filterSearch,
			page: 1,
		},
	});
}

export function* THROTTLE_SET_SEARCH_TEXT() {
	yield call(GET_LIST);
}

export function* SET_FILTERS({ payload }: IDispatchObj) {
	const { filterStatus } = payload;

	yield put({
		type: actions.SET_STATE,
		payload: {
			filterStatus,
		},
	});

	yield call(GET_LIST);
}

export function* RESET_FILTERS() {
	yield put({
		type: actions.SET_STATE,
		payload: {
			filterStatus: "",
		},
	});

	yield call(GET_LIST);
}

export function* SET_ACTIVE_STAFF({ payload }: IDispatchObj) {
	const { activeStaff } = payload;

	yield put({
		type: actions.SET_STATE,
		payload: {
			activeStaff,
		},
	});

	yield call(GET_LIST);
	// yield call(GET_DOC, { payload: {}, type: "" });
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.GET_LIST, GET_LIST),
		takeEvery(actions.ADD, ADD),
		takeEvery(actions.DELETE, DELETE),
		takeEvery(actions.SET_CURRENT_PAGE, SET_CURRENT_PAGE),
		takeEvery(actions.SET_LIMIT, SET_LIMIT),
		takeEvery(actions.SET_SORTING, SET_SORTING),
		takeEvery(actions.SET_SEARCH_TEXT, SET_SEARCH_TEXT),
		debounce(500, actions.SET_SEARCH_TEXT, THROTTLE_SET_SEARCH_TEXT),
		takeEvery(actions.SET_FILTERS, SET_FILTERS),
		takeEvery(actions.RESET_FILTERS, RESET_FILTERS),
		takeEvery(actions.SET_ACTIVE_STAFF, SET_ACTIVE_STAFF),
	]);
}
