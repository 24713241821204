import apiClient from "../axios";

// Offices - List
export const getList =
	async (/* queries: { start: number; length: number } */) =>
		apiClient
			.get(`/offices`, {
				/* headers: { "cache-control": "no-cache" } */
			})
			.then((res) => (res && res.data ? res.data : false))
			.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/offices/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create an office
export const create = async (data: {
	name: string;
	address: string;
	desc?: boolean;
}) =>
	apiClient
		.post("/offices", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update an office
export const update = async (data: {
	name?: string;
	address?: string;
	desc?: boolean;
	id: string | number;
}) =>
	apiClient
		.put(`/offices/${data.id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Activate an office
export const activate = async (data: { reason: string; id: string | number }) =>
	apiClient
		.patch(`/offices/${data.id}/activate`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Deactivate an office
export const deactivate = async (data: {
	reason: string;
	id: string | number;
}) =>
	apiClient
		.patch(`/offices/${data.id}/deactivate`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
