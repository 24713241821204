import apiClient from "../axios";

// BookingInvoice - List
export const getList =
	async (/* queries: { start: number; length: number } */) =>
		apiClient
			.get(`/booking-invoices`, {
				/* headers: { "cache-control": "no-cache" } */
			})
			.then((res) => (res && res.data ? res.data : false))
			.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/booking-invoices/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create a BookingInvoice
export const create = async (data: any) =>
	apiClient
		.post("/booking-invoices", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update a BookingInvoice
export const update = async (data: any) =>
	apiClient
		.put(`/booking-invoices/${data.id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Delete a BookingInvoice
export const deleteBookingInvoice = async (data: {
	reason: string;
	id: string | number;
}) =>
	apiClient
		.delete(`/booking-invoices/${data.id}`, { data })
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Approve a BookingInvoice
export const approve = async (data: any) =>
	apiClient
		.patch(`/booking-invoices/${data.id}/approve`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Reject a BookingInvoice
export const reject = async (data: any) =>
	apiClient
		.patch(`/booking-invoices/${data.id}/reject`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
