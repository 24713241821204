const actions = {
	SET_STATE: "vehicleSupplier/SET_STATE",
	GET_LIST: "vehicleSupplier/GET_LIST",
	GET_DOC: "vehicleSupplier/GET_DOC",
	CREATE: "vehicleSupplier/CREATE",
	UPDATE: "vehicleSupplier/UPDATE",
	DELETE: "vehicleSupplier/DELETE",
	SET_CURRENT_PAGE: "vehicleSupplier/SET_CURRENT_PAGE",
	SET_LIMIT: "vehicleSupplier/SET_LIMIT",
	SET_SORTING: "vehicleSupplier/SET_SORTING",
	SET_SEARCH_TEXT: "vehicleSupplier/SET_SEARCH_TEXT",
	SET_FILTERS: "vehicleSupplier/SET_FILTERS",
	RESET_FILTERS: "vehicleSupplier/RESET_FILTERS",
};

export default actions;
