const actions = {
	SET_STATE: "fuelingType/SET_STATE",
	GET_LIST: "fuelingType/GET_LIST",
	GET_DOC: "fuelingType/GET_DOC",
	CREATE: "fuelingType/CREATE",
	UPDATE: "fuelingType/UPDATE",
	DELETE: "fuelingType/DELETE",
	SET_CURRENT_PAGE: "fuelingType/SET_CURRENT_PAGE",
	SET_LIMIT: "fuelingType/SET_LIMIT",
	SET_SORTING: "fuelingType/SET_SORTING",
	SET_SEARCH_TEXT: "fuelingType/SET_SEARCH_TEXT",
	SET_FILTERS: "fuelingType/SET_FILTERS",
	RESET_FILTERS: "fuelingType/RESET_FILTERS",
};

export default actions;
