const actions = {
	SET_STATE: "maintenanceRating/SET_STATE",
	GET_LIST: "maintenanceRating/GET_LIST",
	GET_DOC: "maintenanceRating/GET_DOC",
	CREATE: "maintenanceRating/CREATE",
	UPDATE: "maintenanceRating/UPDATE",
	DELETE: "maintenanceRating/DELETE",
	ACTIVATE: "maintenanceRating/ACTIVATE",
	DEACTIVATE: "maintenanceRating/DEACTIVATE",
	SET_CURRENT_PAGE: "maintenanceRating/SET_CURRENT_PAGE",
	SET_LIMIT: "maintenanceRating/SET_LIMIT",
	SET_SORTING: "maintenanceRating/SET_SORTING",
	SET_SEARCH_TEXT: "maintenanceRating/SET_SEARCH_TEXT",
	SET_FILTERS: "maintenanceRating/SET_FILTERS",
	RESET_FILTERS: "maintenanceRating/RESET_FILTERS",
};

export default actions;
