const actions = {
	SET_STATE: "vehicleService/SET_STATE",
	GET_LIST: "vehicleService/GET_LIST",
	GET_DOC: "vehicleService/GET_DOC",
	CREATE: "vehicleService/CREATE",
	UPDATE: "vehicleService/UPDATE",
	DELETE: "vehicleService/DELETE",
	SET_CURRENT_PAGE: "vehicleService/SET_CURRENT_PAGE",
	SET_LIMIT: "vehicleService/SET_LIMIT",
	SET_SORTING: "vehicleService/SET_SORTING",
	SET_SEARCH_TEXT: "vehicleService/SET_SEARCH_TEXT",
	SET_FILTERS: "vehicleService/SET_FILTERS",
	RESET_FILTERS: "vehicleService/RESET_FILTERS",
};

export default actions;
