const actions = {
	SET_STATE: "tripStatus/SET_STATE",
	GET_LIST: "tripStatus/GET_LIST",
	GET_DOC: "tripStatus/GET_DOC",
	CREATE: "tripStatus/CREATE",
	UPDATE: "tripStatus/UPDATE",
	DELETE: "tripStatus/DELETE",
	APPROVE: "tripStatus/APPROVE",
	REJECT: "tripStatus/REJECT",
	SET_CURRENT_PAGE: "tripStatus/SET_CURRENT_PAGE",
	SET_LIMIT: "tripStatus/SET_LIMIT",
	SET_SORTING: "tripStatus/SET_SORTING",
	SET_SEARCH_TEXT: "tripStatus/SET_SEARCH_TEXT",
	SET_FILTERS: "tripStatus/SET_FILTERS",
	RESET_FILTERS: "tripStatus/RESET_FILTERS",
};

export default actions;
