import apiClient from "../axios";

// Users - List
export const getList = async (queries: {
	start: number;
	length: number;
	filterSearch?: string;
	filterStatus?: string;
	filterOffice?: number;
	filterUnit?: number;
}) =>
	apiClient
		.get(
			`/users?start=${queries.start}&length=${queries.length}&filter[search]=${queries.filterSearch}&filter[status]=${queries.filterStatus}&filter[office_id]=${queries.filterOffice}&filter[unit_id]=${queries.filterUnit}`,
			{
				/* headers: { "cache-control": "no-cache" } */
			}
		)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/users/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Reset password of a user
export const resetPassword = async (data: {
	password: string;
	password_confirmation: boolean;
	id: string | number;
}) =>
	apiClient
		.patch(`/users/${data.id}/reset-password`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Activate a user
export const activate = async (data: { reason: string; id: string | number }) =>
	apiClient
		.patch(`/users/${data.id}/activate`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Deactivate a user
export const deactivate = async (data: {
	reason: string;
	id: string | number;
}) =>
	apiClient
		.patch(`/users/${data.id}/deactivate`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Force verify a user account
export const forceVerify = async (data: { id: string | number }) =>
	apiClient
		.patch(`/users/${data.id}/verify`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Staff's Permission - List
export const getPermisisonList = async (data: any) =>
	apiClient
		.get(`/users/${data.id}/permissions`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Add permission
export const addPermission = async (data: {
	id: string | number;
	permissions: string[];
}) =>
	apiClient
		.post(`/users/${data.id}/permissions`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Delete a permission
export const deletePermission = async (data: {
	id: string | number;
	permission_id: string | number;
}) =>
	apiClient
		.delete(`/users/${data.id}/permissions/${data.permission_id}`)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
