import apiClient from "../axios";

// Driver Licence - List
export const getList =
	async (/* queries: { start: number; length: number } */) =>
		apiClient
			.get(`/driver-licences`, {
				/* headers: { "cache-control": "no-cache" } */
			})
			.then((res) => (res && res.data ? res.data : false))
			.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/driver-licences/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create a driver-licences
export const create = async (data: any) =>
	apiClient
		.post("/driver-licences", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update a driver-licences
export const update = async (data: any) =>
	apiClient
		.put(`/driver-licences/${data.id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Delete a driver-licences
export const deleteDriverLicence = async (data: {
	reason: string;
	id: string | number;
}) =>
	apiClient
		.delete(`/driver-licences/${data.id}`, { data })
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Approve a driver-licences
export const approve = async (data: any) =>
	apiClient
		.patch(`/driver-licences/${data.id}/mark-as-verified`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Reject a driver-licences
export const reject = async (data: any) =>
	apiClient
		.patch(`/driver-licences/${data.id}/mark-as-rejected`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
