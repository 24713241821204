const actions = {
	SET_STATE: "reservation/SET_STATE",
	GET_LIST: "reservation/GET_LIST",
	GET_DOC: "reservation/GET_DOC",
	CREATE: "reservation/CREATE",
	UPDATE: "reservation/UPDATE",
	DELETE: "reservation/DELETE",
	SET_CURRENT_PAGE: "reservation/SET_CURRENT_PAGE",
	SET_LIMIT: "reservation/SET_LIMIT",
	SET_SORTING: "reservation/SET_SORTING",
	SET_SEARCH_TEXT: "reservation/SET_SEARCH_TEXT",
	SET_FILTERS: "reservation/SET_FILTERS",
	RESET_FILTERS: "reservation/RESET_FILTERS",
};

export default actions;
