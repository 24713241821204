const actions = {
	SET_STATE: "cost/SET_STATE",
	GET_LIST: "cost/GET_LIST",
	GET_DOC: "cost/GET_DOC",
	CREATE: "cost/CREATE",
	UPDATE: "cost/UPDATE",
	DELETE: "cost/DELETE",
	APPROVE: "cost/APPROVE",
	REJECT: "cost/REJECT",
	SET_CURRENT_PAGE: "cost/SET_CURRENT_PAGE",
	SET_LIMIT: "cost/SET_LIMIT",
	SET_SORTING: "cost/SET_SORTING",
	SET_SEARCH_TEXT: "cost/SET_SEARCH_TEXT",
	SET_FILTERS: "cost/SET_FILTERS",
	RESET_FILTERS: "cost/RESET_FILTERS",
};

export default actions;
