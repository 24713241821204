const actions = {
	SET_STATE: "accountNumber/SET_STATE",
	GET_LIST: "accountNumber/GET_LIST",
	GET_DOC: "accountNumber/GET_DOC",
	CREATE: "accountNumber/CREATE",
	UPDATE: "accountNumber/UPDATE",
	DELETE: "accountNumber/DELETE",
	ACTIVATE: "accountNumber/ACTIVATE",
	DEACTIVATE: "accountNumber/DEACTIVATE",
	SET_CURRENT_PAGE: "accountNumber/SET_CURRENT_PAGE",
	SET_LIMIT: "accountNumber/SET_LIMIT",
	SET_SORTING: "accountNumber/SET_SORTING",
	SET_SEARCH_TEXT: "accountNumber/SET_SEARCH_TEXT",
	SET_FILTERS: "accountNumber/SET_FILTERS",
	RESET_FILTERS: "accountNumber/RESET_FILTERS",
};

export default actions;
