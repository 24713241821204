import apiClient from "../axios";

// Fueling - List
export const getList =
	async (/* queries: { start: number; length: number } */) =>
		apiClient
			.get(`/contract/types`, {
				/* headers: { "cache-control": "no-cache" } */
			})
			.then((res) => (res && res.data ? res.data : false))
			.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/contract/types/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create a contract/types
export const create = async (data: any) =>
	apiClient
		.post("/contract/types", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update a contract/types
export const update = async (data: any) =>
	apiClient
		.put(`/contract/types/${data.id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Delete a contract/types
export const deleteContractType = async (data: {
	reason: string;
	id: string | number;
}) =>
	apiClient
		.delete(`/contract/types/${data.id}`, { data })
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
