const findChildNodeRecursively = (
	children: any,
	className: any,
	className2: any
) => {
	let res = null;

	children?.forEach((obj: any) => {
		if (
			obj?.className?.split(" ").includes(className) ||
			obj?.className?.split(" ").includes(className2)
		) {
			res = obj;
		}
	});

	return res;
};

export default findChildNodeRecursively;
