const actions = {
	SET_STATE: "assetSupplier/SET_STATE",
	GET_LIST: "assetSupplier/GET_LIST",
	GET_DOC: "assetSupplier/GET_DOC",
	CREATE: "assetSupplier/CREATE",
	UPDATE: "assetSupplier/UPDATE",
	DELETE: "assetSupplier/DELETE",
	ACTIVATE: "assetSupplier/ACTIVATE",
	DEACTIVATE: "assetSupplier/DEACTIVATE",
	SET_CURRENT_PAGE: "assetSupplier/SET_CURRENT_PAGE",
	SET_LIMIT: "assetSupplier/SET_LIMIT",
	SET_SORTING: "assetSupplier/SET_SORTING",
	SET_SEARCH_TEXT: "assetSupplier/SET_SEARCH_TEXT",
	SET_FILTERS: "assetSupplier/SET_FILTERS",
	RESET_FILTERS: "assetSupplier/RESET_FILTERS",
};

export default actions;
