import actions from "./actions";

const initialState = {
	showMenuDrawer: false,
};

export default function settingsReducer(state = initialState, action: any) {
	switch (action.type) {
		case actions.SET_STATE:
			return { ...state, ...action.payload };
		default:
			return state;
	}
}
