import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { connectRouter } from "connected-react-router";
import storage from "redux-persist/lib/storage";

import account from "./account/reducers";
import office from "./office/reducers";
import unit from "./unit/reducers";
import staff from "./staff/reducers";
import staffPermission from "./staffPermission/reducers";
import settings from "./settings/reducers";
import menu from "./menu/reducers";
import driver from "./driver/reducers";
import vehicle from "./vehicle/reducers";
import vehicleType from "./vehicleType/reducers";
import vehicleGroup from "./vehicleGroup/reducers";
import vehicleMake from "./vehicleMake/reducers";
import vehicleService from "./vehicleService/reducers";
import driverVehicle from "./driverVehicle/reducers";
import batterySpec from "./batterySpec/reducers";
import company from "./company/reducers";
import contactPerson from "./contactPerson/reducers";
import docAssetType from "./docAssetType/reducers";
import docFolder from "./docFolder/reducers";
import docType from "./docType/reducers";
import entityType from "./entityType/reducers";
import fueling from "./fueling/reducers";
import fuelingType from "./fuelingType/reducers";
import fuelType from "./fuelType/reducers";
import fillingStation from "./fillingStation/reducers";
import contractType from "./contractType/reducers";
import contract from "./contract/reducers";
import serviceType from "./serviceType/reducers";
import reservation from "./reservation/reducers";
import trip from "./trip/reducers";
import tripStatus from "./tripStatus/reducers";
import invoiceStatus from "./invoiceStatus/reducers";
import additionalService from "./additionalService/reducers";
import cost from "./cost/reducers";
import userApprovalRequest from "./userApprovalRequest/reducers";
import bookingInvoice from "./bookingInvoice/reducers";
import assetSupplier from "./assetSupplier/reducers";
import location from "./location/reducers";
import tyreSpec from "./tyreSpec/reducers";
import vehicleSupplier from "./vehicleSupplier/reducers";
import vendor from "./vendor/reducers";
import financier from "./financier/reducers";
import transmissionType from "./transmissionType/reducers";
import docInsuranceCert from "./docInsuranceCert/reducers";
import docAsset from "./docAsset/reducers";
import vendorBankAcc from "./vendorBankAcc/reducers";
import driverLicence from "./driverLicence/reducers";
import accountNumber from "./accountNumber/reducers";
import misc from "./misc/reducers";
import maintenanceWorkshop from "./maintenanceWorkshop/reducers";
import maintenanceRating from "./maintenanceRating/reducers";
import maintenanceType from "./maintenanceType/reducers";
import maintenanceSubType from "./maintenanceSubType/reducers";
import maintenanceFaultType from "./maintenanceFaultType/reducers";
import companyContract from "./companyContract/reducers";
import companyVehicle from "./companyVehicle/reducers";

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["account", "misc"],
};

const rootReducer = (history: any) =>
	combineReducers({
		router: connectRouter(history),
		account,
		office,
		unit,
		staff,
		staffPermission,
		settings,
		menu,
		driver,
		vehicle,
		vehicleType,
		vehicleGroup,
		vehicleMake,
		vehicleService,
		driverVehicle,
		vendor,
		batterySpec,
		company,
		contactPerson,
		docAssetType,
		docFolder,
		docType,
		entityType,
		fueling,
		fuelingType,
		fuelType,
		fillingStation,
		contractType,
		contract,
		serviceType,
		assetSupplier,
		reservation,
		trip,
		tripStatus,
		invoiceStatus,
		additionalService,
		cost,
		userApprovalRequest,
		bookingInvoice,
		location,
		tyreSpec,
		vehicleSupplier,
		financier,
		transmissionType,
		docInsuranceCert,
		docAsset,
		vendorBankAcc,
		driverLicence,
		accountNumber,
		misc,
		maintenanceWorkshop,
		maintenanceRating,
		maintenanceType,
		maintenanceSubType,
		maintenanceFaultType,
		companyContract,
		companyVehicle,
	});

export default (history: any) =>
	persistReducer(persistConfig, rootReducer(history));

export type IRootState = {
	router: any;
	account: any;
	office: any;
	unit: any;
	staff: any;
	staffPermission: any;
	settings: any;
	menu: any;
	driver: any;
	vehicle: any;
	vehicleType: any;
	vehicleGroup: any;
	vehicleMake: any;
	vehicleService: any;
	driverVehicle: any;
	vendor: any;
	batterySpec: any;
	company: any;
	contactPerson: any;
	docAssetType: any;
	docAsset: any;
	docInsuranceCert: any;
	docFolder: any;
	docType: any;
	entityType: any;
	fueling: any;
	fuelingType: any;
	fuelType: any;
	fillingStation: any;
	assetSupplier: any;
	serviceType: any;
	contractType: any;
	contract: any;
	reservation: any;
	trip: any;
	tripStatus: any;
	invoiceStatus: any;
	additionalService: any;
	cost: any;
	userApprovalRequest: any;
	bookingInvoice: any;
	location: any;
	tyreSpec: any;
	vehicleSupplier: any;
	financier: any;
	transmissionType: any;
	vendorBankAcc: any;
	driverLicence: any;
	accountNumber: any;
	misc: any;
	maintenanceWorkshop: any;
	maintenanceRating: any;
	maintenanceType: any;
	maintenanceSubType: any;
	maintenanceFaultType: any;
	companyContract: any;
	companyVehicle: any;
};
