const actions = {
	SET_STATE: "company/SET_STATE",
	GET_LIST: "company/GET_LIST",
	GET_DOC: "company/GET_DOC",
	CREATE: "company/CREATE",
	UPDATE: "company/UPDATE",
	DELETE: "company/DELETE",
	SET_CURRENT_PAGE: "company/SET_CURRENT_PAGE",
	SET_LIMIT: "company/SET_LIMIT",
	SET_SORTING: "company/SET_SORTING",
	SET_SEARCH_TEXT: "company/SET_SEARCH_TEXT",
	SET_FILTERS: "company/SET_FILTERS",
	RESET_FILTERS: "company/RESET_FILTERS",
};

export default actions;
