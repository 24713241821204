import React from "react";
import { useSelector } from "react-redux";
// import NProgress from "nprogress";
import { Helmet } from "react-helmet";
import { useLocation, Navigate, Outlet } from "react-router-dom";

import { IRootState } from "@redux/reducers";
import PublicLayout from "./Public";
import AuthLayout from "./Auth";
import MainLayout from "./Main";
// import SubLayout from "./Sub";
// import useForceUpdate from "../hooks/useForceUpdate";

const Layouts = {
	public: PublicLayout,
	auth: AuthLayout,
	main: MainLayout,
	// sub: SubLayout,
};

// let previousPath = "";

const Layout: React.FC<any> = ({ history, children }) => {
	// const forceUpdate = useForceUpdate();
	const location = useLocation();
	// const { pathname, search } = location;

	/* React.useEffect(() => {
    console.log("reaching");
    forceUpdate();
  }, [pathname]); */

	return (
		<>
			<Helmet titleTemplate="Groot" title="App" />
			<BootstappedLayout history={history}>
				<Outlet />
			</BootstappedLayout>
		</>
	);
};

const BootstappedLayout: React.FC<{
	// pathname: string;
	history: any;
	children: React.ReactNode;
}> = ({ history, children }: any) => {
	const location = useLocation();
	const { pathname } = location;

	const { authorized, loadingUser } = useSelector<IRootState, any>(
		(state) => state.account
	);

	// Layout Rendering
	const getLayout = () => {
		/* if (
			[
				"/error/401",
				"/error/403",
				"/error/404",
				"/error/500",
				"/error/bad-token",
				"/error/no-account",
				"/privacy-policy",
				"/company",
				"/contact",
				"/pricing",
				"/",
			].includes(pathname)
		) {
			return "public";
		} */

		if (
			/^\/auth(?=\/|$)/i.test(pathname) ||
			/^\/account(?=\/|$)/i.test(pathname)
		) {
			return "auth";
		}

		return "public";
	};

	const Container = Layouts[getLayout()];
	const isUserAuthorized = authorized;
	// const isRoleLoaded = ac && ac.grants;
	const isUserLoading = loadingUser;
	const isAuthLayout = getLayout() === "auth";
	const isPublicLayout = getLayout() === "public";
	// const isSubLayout = getLayout() === "sub";
	// const isMainLayout = getLayout() === "main";

	// show loader when the user not authorized yet, role not loaded yet
	if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
		return null;
	}

	// redirect to signin page if current layout is not authentication layout and user is not authorized
	if (!isAuthLayout && !isPublicLayout && !isUserAuthorized) {
		return <Navigate to="/auth/signin" state={{ from: location }} replace />;
	}

	return <Container history={history}>{children}</Container>;
};

export default Layout;
