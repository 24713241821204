const actions = {
	SET_STATE: "driverVehicle/SET_STATE",
	GET_LIST: "driverVehicle/GET_LIST",
	GET_DOC: "driverVehicle/GET_DOC",
	CREATE: "driverVehicle/CREATE",
	UPDATE: "driverVehicle/UPDATE",
	DELETE: "driverVehicle/DELETE",
	SET_CURRENT_PAGE: "driverVehicle/SET_CURRENT_PAGE",
	SET_LIMIT: "driverVehicle/SET_LIMIT",
	SET_SORTING: "driverVehicle/SET_SORTING",
	SET_SEARCH_TEXT: "driverVehicle/SET_SEARCH_TEXT",
	SET_FILTERS: "driverVehicle/SET_FILTERS",
	RESET_FILTERS: "driverVehicle/RESET_FILTERS",
	SET_ACTIVE_DRIVER: "driverVehicle/SET_ACTIVE_DRIVER",
};

export default actions;
