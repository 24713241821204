import {
	all,
	put,
	call,
	takeEvery,
	// debounce,
	// select,
} from "redux-saga/effects";
// import { notification } from "antd";

import { getCurrencies } from "@services/api/misc";
// import { IDispatchObj, IServerResponse } from "@interfaces/custom";
import actions from "./actions";

export function* GET_CURRENCY_LIST() {
	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingCurrencyList: true,
		},
	});

	const res: { data: any[] } | undefined = yield call(getCurrencies);

	if (res && res.data) {
		yield put({
			type: actions.SET_STATE,
			payload: {
				currencyList: res.data,
			},
		});
	}

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingCurrencyList: false,
		},
	});
}

export default function* rootSaga() {
	yield all([takeEvery(actions.GET_CURRENCY_LIST, GET_CURRENCY_LIST)]);
}
