import apiClient from "../axios";

// Driver - List
export const getList =
	async (/* queries: { start: number; length: number } */) =>
		apiClient
			.get(`/drivers`, {
				/* headers: { "cache-control": "no-cache" } */
			})
			.then((res) => (res && res.data ? res.data : false))
			.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/drivers/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create a driver
export const create = async (data: any) =>
	apiClient
		.post("/drivers", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update a driver
export const update = async (data: any) =>
	apiClient
		.put(`/drivers/${data.id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Delete a driver
export const deleteDriver = async (data: {
	reason: string;
	id: string | number;
}) =>
	apiClient
		.delete(`/drivers/${data.id}`, { data })
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Driver's Vehicle - List
export const getVehicleList = async (data: any) =>
	apiClient
		.get(`/drivers/${data.id}/vehicles`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create a driver's vehicle
export const createVehicle = async (data: {
	id: string | number;
	driver_id: string | number;
	vehicle_id: string | number;
}) =>
	apiClient
		.post(`/drivers/${data.id}/vehicles`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update a driver's vehicle
export const updateVehicle = async (data: {
	driver_id: string | number;
	vehicle_id: string | number;
	driverIdToUpdate: string | number;
	vehicleIdToUpdate: string | number;
}) =>
	apiClient
		.put(
			`/drivers/${data.driverIdToUpdate}/vehicles/${data.vehicleIdToUpdate}`,
			data
		)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// View Driver's Vehicle Info
export const getVehicleDoc = async (data: {
	id: string | number;
	vehicleId: string | number;
}) =>
	apiClient
		.get(`/drivers/${data.id}/vehicles/${data.vehicleId}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Delete a driver's vehicle
export const deleteVehicle = async (data: {
	reason: string;
	id: string | number;
	vehicle_id: string | number;
}) =>
	apiClient
		.delete(`/drivers/${data.id}/vehicles/${data.vehicle_id}`, { data })
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
