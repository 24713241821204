const actions = {
	SET_STATE: "serviceType/SET_STATE",
	GET_LIST: "serviceType/GET_LIST",
	GET_DOC: "serviceType/GET_DOC",
	CREATE: "serviceType/CREATE",
	UPDATE: "serviceType/UPDATE",
	DELETE: "serviceType/DELETE",
	ACTIVATE: "serviceType/ACTIVATE",
	DEACTIVATE: "serviceType/DEACTIVATE",
	SET_CURRENT_PAGE: "serviceType/SET_CURRENT_PAGE",
	SET_LIMIT: "serviceType/SET_LIMIT",
	SET_SORTING: "serviceType/SET_SORTING",
	SET_SEARCH_TEXT: "serviceType/SET_SEARCH_TEXT",
	SET_FILTERS: "serviceType/SET_FILTERS",
	RESET_FILTERS: "serviceType/RESET_FILTERS",
};

export default actions;
