import apiClient from "../axios";

// Company - List
export const getList =
	async (/* queries: { start: number; length: number } */) =>
		apiClient
			.get(`/companies`, {
				/* headers: { "cache-control": "no-cache" } */
			})
			.then((res) => (res && res.data ? res.data : false))
			.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/companies/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create a company
export const create = async (data: any) =>
	apiClient
		.post("/companies", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update a company
export const update = async (data: any) =>
	apiClient
		.put(`/companies/${data.id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Delete a company
export const deleteCompany = async (data: {
	reason: string;
	id: string | number;
}) =>
	apiClient
		.delete(`/companies/${data.id}`, { data })
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Get a company's contact person list
export const getContactPersonList = async (data: any) =>
	apiClient
		.get(`/companies/${data.id}/contact-person`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create a company's contact person
export const createContactPerson = async (data: any) =>
	apiClient
		.post(`/companies/${data.id}/contact-person`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update a company's contact person
export const updateContactPerson = async (data: any) =>
	apiClient
		.put(
			`/companies/${data.id}/contact-person/${data.company_contact_person_id}`,
			data
		)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Delete a company's contact person
export const deleteContactPerson = async (data: any) =>
	apiClient
		.delete(
			`/companies/${data.id}/contact-person/${data.company_contact_person_id}`,
			{ data }
		)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Get a company's contract list
export const getContractList = async (data: any) =>
	apiClient
		.get(`/companies/${data.id}/contracts`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Get a company's vehicle list
export const getVehicleList = async (data: any) =>
	apiClient
		.get(`/companies/${data.id}/vehicles`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
