import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Button, Menu } from "antd";
import TweenOne from "rc-tween-one";
import store from "store";
import { find } from "lodash";

import { getChildrenToRender } from "utils/misc";

const { Item, SubMenu } = Menu;

const Header = ({ dataSource, isMobile, ...props }: any) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const dispatch = useDispatch();

	const [phoneOpen, setPhoneOpen] = useState<any>(false);
	const [selectedKeys, setSelectedKeys] = useState(
		store.get("app.menu.selectedKeys") || []
	);

	useEffect(() => {
		applySelectedKeys();
	}, [pathname]);

	const phoneClick = () => setPhoneOpen(!phoneOpen);
	const navData = dataSource.Menu.children;

	const applySelectedKeys = () => {
		const flattenItems = (items: any, key: any) =>
			items.reduce((flattenedItems: any, item: any) => {
				flattenedItems.push(item);
				if (Array.isArray(item[key])) {
					return flattenedItems.concat(flattenItems(item[key], key));
				}
				return flattenedItems;
			}, []);

		let selectedItem = find(flattenItems(navData, "children"), [
			"name",
			`${pathname.split("/")[1]}`,
		]);

		if (!selectedItem && !pathname.split("/")[1]) {
			selectedItem = {
				name: "home",
			};
		}

		setSelectedKeys(selectedItem ? [selectedItem.name] : [""]);
		store.set(
			"app.menu.selectedKeys",
			selectedItem ? [selectedItem.name] : [""]
		);
	};

	// const onClick = (e: any) => {
	// 	if (e.key === "home") {
	// 		navigate("");
	// 	} else {
	// 		navigate(e.key);
	// 	}
	// };

	const navChildren = navData.map((item: any) => {
		const { children: a, subItem, ...itemProps } = item;

		if (subItem) {
			return (
				<SubMenu
					key={item.name}
					{...itemProps}
					title={
						<div {...a} className={`header0-item-block ${a.className}`.trim()}>
							{a.children.map(getChildrenToRender)}
						</div>
					}
					popupClassName="header0-item-child"
				>
					{subItem.map(($item: any, ii: any) => {
						const { children: childItem } = $item;
						const child = childItem.href ? (
							<Link to={item.name} {...childItem}>
								{childItem.children.map(getChildrenToRender)}
							</Link>
						) : (
							<div {...childItem}>
								{childItem.children.map(getChildrenToRender)}
							</div>
						);

						return (
							<Item key={$item.name || ii.toString()} {...$item}>
								{child}
							</Item>
						);
					})}
				</SubMenu>
			);
		}

		return (
			<Item key={item.name} {...itemProps}>
				<Link
					to={item.name === "home" ? "/" : item.name}
					{...a}
					className={`header0-item-block ${a.className}`.trim()}
				>
					{a.children.map(getChildrenToRender)}
				</Link>
			</Item>
		);
	});

	const moment = phoneOpen === false ? 300 : null;

	return (
		<TweenOne
			component="header"
			animation={{ type: "from" }}
			{...dataSource.wrapper}
			{...props}
		>
			<div
				{...dataSource.page}
				className={`${dataSource.page.className}${phoneOpen ? " open" : ""}`}
			>
				<TweenOne
					animation={{ x: -30, opacity: 0, type: "from", ease: "easeOutQuad" }}
					{...dataSource.logo}
				>
					<img height={33} src={dataSource.logo.children} alt="img" />
				</TweenOne>

				{isMobile && (
					<Button
						{...dataSource.mobileMenu}
						onClick={() => {
							phoneClick();
						}}
					/>
				)}

				<TweenOne
					{...dataSource.Menu}
					animation={
						isMobile
							? {
									height: 0,
									duration: 300,
									onComplete: (e: any) => {
										if (phoneOpen && e) {
											e.target.style.height = "auto";
										}
									},
									ease: "easeInOutQuad",
							  }
							: null
					}
					moment={moment}
					reverse={!!phoneOpen}
				>
					<Menu
						mode={isMobile ? "inline" : "horizontal"}
						// defaultSelectedKeys={["item0"]}
						// onClick={onClick}
						selectedKeys={selectedKeys}
						theme="dark"
					>
						{navChildren}
					</Menu>
				</TweenOne>
			</div>
		</TweenOne>
	);
};

export default Header;
