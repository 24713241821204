const actions = {
	SET_STATE: "maintenanceSubType/SET_STATE",
	GET_LIST: "maintenanceSubType/GET_LIST",
	GET_DOC: "maintenanceSubType/GET_DOC",
	CREATE: "maintenanceSubType/CREATE",
	UPDATE: "maintenanceSubType/UPDATE",
	DELETE: "maintenanceSubType/DELETE",
	ACTIVATE: "maintenanceSubType/ACTIVATE",
	DEACTIVATE: "maintenanceSubType/DEACTIVATE",
	SET_CURRENT_PAGE: "maintenanceSubType/SET_CURRENT_PAGE",
	SET_LIMIT: "maintenanceSubType/SET_LIMIT",
	SET_SORTING: "maintenanceSubType/SET_SORTING",
	SET_SEARCH_TEXT: "maintenanceSubType/SET_SEARCH_TEXT",
	SET_FILTERS: "maintenanceSubType/SET_FILTERS",
	RESET_FILTERS: "maintenanceSubType/RESET_FILTERS",
};

export default actions;
