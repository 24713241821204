const actions = {
	SET_STATE: "vehicle/SET_STATE",
	GET_LIST: "vehicle/GET_LIST",
	GET_DOC: "vehicle/GET_DOC",
	CREATE: "vehicle/CREATE",
	UPDATE: "vehicle/UPDATE",
	DELETE: "vehicle/DELETE",
	SET_CURRENT_PAGE: "vehicle/SET_CURRENT_PAGE",
	SET_LIMIT: "vehicle/SET_LIMIT",
	SET_SORTING: "vehicle/SET_SORTING",
	SET_SEARCH_TEXT: "vehicle/SET_SEARCH_TEXT",
	SET_FILTERS: "vehicle/SET_FILTERS",
	RESET_FILTERS: "vehicle/RESET_FILTERS",
};

export default actions;
