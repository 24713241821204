import actions from "./actions";

const initialState = {
	menuData: [],
	showDrawer: false,
	changeKey: 4494949,
};

export default function userReducer(state = initialState, action: any) {
	switch (action.type) {
		case actions.SET_STATE:
			return { ...state, ...action.payload };
		default:
			return state;
	}
}
