const actions = {
	SET_STATE: "vehicleMake/SET_STATE",
	GET_LIST: "vehicleMake/GET_LIST",
	GET_DOC: "vehicleMake/GET_DOC",
	CREATE: "vehicleMake/CREATE",
	UPDATE: "vehicleMake/UPDATE",
	DELETE: "vehicleMake/DELETE",
	SET_CURRENT_PAGE: "vehicleMake/SET_CURRENT_PAGE",
	SET_LIMIT: "vehicleMake/SET_LIMIT",
	SET_SORTING: "vehicleMake/SET_SORTING",
	SET_SEARCH_TEXT: "vehicleMake/SET_SEARCH_TEXT",
	SET_FILTERS: "vehicleMake/SET_FILTERS",
	RESET_FILTERS: "vehicleMake/RESET_FILTERS",
};

export default actions;
