const actions = {
	SET_STATE: "tyreSpec/SET_STATE",
	GET_LIST: "tyreSpec/GET_LIST",
	GET_DOC: "tyreSpec/GET_DOC",
	CREATE: "tyreSpec/CREATE",
	UPDATE: "tyreSpec/UPDATE",
	DELETE: "tyreSpec/DELETE",
	SET_CURRENT_PAGE: "tyreSpec/SET_CURRENT_PAGE",
	SET_LIMIT: "tyreSpec/SET_LIMIT",
	SET_SORTING: "tyreSpec/SET_SORTING",
	SET_SEARCH_TEXT: "tyreSpec/SET_SEARCH_TEXT",
	SET_FILTERS: "tyreSpec/SET_FILTERS",
	RESET_FILTERS: "tyreSpec/RESET_FILTERS",
};

export default actions;
