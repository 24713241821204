const actions = {
	SET_STATE: "companyVehicle/SET_STATE",
	GET_LIST: "companyVehicle/GET_LIST",
	SET_CURRENT_PAGE: "companyVehicle/SET_CURRENT_PAGE",
	SET_LIMIT: "companyVehicle/SET_LIMIT",
	SET_SORTING: "companyVehicle/SET_SORTING",
	SET_SEARCH_TEXT: "companyVehicle/SET_SEARCH_TEXT",
	SET_FILTERS: "companyVehicle/SET_FILTERS",
	RESET_FILTERS: "companyVehicle/RESET_FILTERS",
	SET_ACTIVE_COMPANY: "companyVehicle/SET_ACTIVE_COMPANY",
};

export default actions;
