import React from "react";
import { Helmet } from "react-helmet";
import { Layout } from "antd";

type AuthLayoutProps = {
	children: React.ReactNode;
};

function AuthLayout({ children }: AuthLayoutProps) {
	return (
		<>
			<Helmet titleTemplate="%s | Groot" />
			<Layout>
				<Layout.Content>{children}</Layout.Content>
			</Layout>
		</>
	);
}

export default AuthLayout;
