import apiClient from "../axios";

// Vendor - List
export const getList =
	async (/* queries: { start: number; length: number } */) =>
		apiClient
			.get(`/vendors`, {
				/* headers: { "cache-control": "no-cache" } */
			})
			.then((res) => (res && res.data ? res.data : false))
			.catch((err) => console.log(err));

// View Info
export const getDoc = async (id: string) =>
	apiClient
		.get(`/vendors/${id}`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create a vendor
export const create = async (data: any) =>
	apiClient
		.post("/vendors", data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update a vendor
export const update = async (data: any) =>
	apiClient
		.put(`/vendors/${data.id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Delete a vendor
export const deleteVendor = async (data: {
	reason: string;
	id: string | number;
}) =>
	apiClient
		.delete(`/vendors/${data.id}`, { data })
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Activate a vendor
export const activate = async (data: { reason: string; id: string | number }) =>
	apiClient
		.patch(`/vendors/${data.id}/activate`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Deactivate a vendor
export const deactivate = async (data: {
	reason: string;
	id: string | number;
}) =>
	apiClient
		.patch(`/vendors/${data.id}/deactivate`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// View Account Number
export const getAccountNo = async (data: any) =>
	apiClient
		.get(`/vendors/${data.id}/account-number`, {
			/* headers: { "cache-control": "no-cache" } */
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Create Account Number
export const createAccountNo = async (data: {
	bank_name: string;
	account_name: string;
	account_number: string;
	id: string | number;
}) =>
	apiClient
		.post(`/vendors/${data.id}/account-number`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Update Account Number
export const updateAccountNo = async (data: {
	bank_name: string;
	account_name: string;
	account_number: string;
	id: string | number;
	account_id: string | number;
}) =>
	apiClient
		.patch(`/vendors/${data.id}/account-number${data.account_id}`, data)
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));

// Delete Account Number
export const deleteAccountNo = async (data: {
	reason: string;
	id: string | number;
	account_id: string | number;
}) =>
	apiClient
		.delete(`/vendors/${data.id}/account-number${data.account_id}`, {
			data,
		})
		.then((res) => (res && res.data ? res.data : false))
		.catch((err) => console.log(err));
